import React, { useCallback, useState, useEffect, useRef} from "react";
import MenuList from "../component/MenuList";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import TenKey from "../component/TenKey";
import Azumen from '../img/room_sozai/A_zu.png'
import Bzumen from '../img/room_sozai/B_zu.png'
import Czumen from '../img/room_sozai/C_zu.png'
import Dzumen from '../img/room_sozai/D_zu.png'
import Ezumen from '../img/room_sozai/E_zu.png'
import Amm from '../img/roomplan_sozai2/Amm.png'
import Bmm from '../img/roomplan_sozai2/bmm.png'
import Cmm from '../img/roomplan_sozai2/cmm.png'
import Dmm from '../img/roomplan_sozai2/dmm.png'
import Emm from '../img/roomplan_sozai2/emm.png'

const Simulation = () => {
    const path = window.location.href
    const id = path.split('/#/')[1]
    var roomNumber = []
    var roomNumber1 = []
    var roomPrice = []
    var roomPrice1 = []
    const [roomPlan, setRoomPlan] = useState([])
    const [roomNum, setRoomNum] = useState("")
    const [rate, setRate] = useState("0.725")
    const [payment, setPayment] = useState("")
    const [period, setPeriod] = useState("35")
    const [bonus, setBonus] = useState("")
    const [month, setMonth] = useState("");
    const roomNumRef = useRef(null);
    const rateRef = useRef(null);
    const paymentRef = useRef(null);
    const periodRef = useState(null);
    const bonusRef = useState(null);
    const [activeField, setActiveField] = useState(null);
    const [showRoomNumpad, setShowRoomNumpad] = useState(0);
    const handleInputClick = (index) => {
        setShowRoomNumpad(index)
    };
    const handleInputClose = () => {
        setShowRoomNumpad(0)
    };
    function handleClick(num) {
        if(activeField === "field1") {
            setRoomNum((prevValue) => prevValue + num)
        } else if(activeField === "field2") {
            setRate((prevValue) => prevValue + num)
        } else if(activeField === "field3") {
            setPayment((prevValue) => prevValue + num)
        } else if(activeField === "field4") {
            setPeriod((prevValue) => prevValue + num)
        } else if(activeField === "field5") {
            setBonus((prevValue) => prevValue + num)
        }
    }
    function handleClose(num) {
        if(activeField === "field1") {
            setRoomNum("")
        } else if(activeField === "field2") {
            setRate("")
        } else if(activeField === "field3") {
            setPayment("")
        } else if(activeField === "field4") {
            setPeriod("")
        } else if(activeField === "field5") {
            setBonus("")
        }
    }

    function handleFocus(event) {
        setActiveField(event.target.name);
    }
    const inputRoomNum = useCallback((e) => {
        setRoomNum(e.target.value)
    }, [setRoomNum])
    const inputRate = useCallback((e) => {
        setRate(e.target.value)
    }, [setRate])
    const inputPayment = useCallback((e) => {
        setPayment(e.target.value)
    }, [setPayment])
    const inputPeriod = useCallback((e) => {
        setPeriod(e.target.value)
    }, [setPeriod])
    const inputBonus = useCallback((e) => {
        setBonus(e.target.value)
    }, [setBonus])
    const inputMonth = useCallback((e) => {
        setMonth(e.target.value)
    }, [setMonth])

    useEffect(() => {
        const roomRef = doc(db, 'apartment', 'sIXBcOFB9TRMxTh8vI5b');
        getDoc(roomRef).then(snapshot => {
            const data = snapshot.data();
            setRoomPlan(data.room)
        })
    }, [])
    roomNumber1 = roomPlan.map(doc => doc.num)
    roomPrice1 = roomPlan.map(doc => doc.price)
    roomNumber.push(roomNumber1)
    roomPrice.push(roomPrice1)
    roomNumber = [].concat.apply([], roomNumber)
    roomPrice = [].concat.apply([], roomPrice)
    var index = roomNumber.indexOf(roomNum)

    return(
    <div className="root">
        <div className="main_container">
            <div className='simulate_right'>
                <h2>LOAN SIMULATION</h2>
                {(() => {
                    if(roomNum === 101 || roomNum === 201 || roomNum === 301 || roomNum === 401 || roomNum === 501 || roomNum === 601 || roomNum === 701 || roomNum === 801 || roomNum === 901 || roomNum === 1001 || roomNum === 1201 || roomNum === 1301 || roomNum === 1401) {
                        return <div className="room_type_view">
                            <img src={Azumen} />
                            <div className="space_small_module" />
                            <img src={Amm} />
                        </div>
                    } else if(roomNum === 102 || roomNum === 202 || roomNum === 302 || roomNum === 402 || roomNum === 502 || roomNum === 602 || roomNum === 702 || roomNum === 802 || roomNum === 902 || roomNum === 1002 || roomNum === 1202 || roomNum === 1302 || roomNum === 1402) {
                        return <div className="room_type_view">
                        <img src={Bzumen} />
                        <div className="space_small_module" />
                        <img src={Bmm} />
                    </div>
                    } else if(roomNum === 103 || roomNum === 203 || roomNum === 303 || roomNum === 403 || roomNum === 503 || roomNum === 603 || roomNum === 703 || roomNum === 803 || roomNum === 903 || roomNum === 1003 || roomNum === 1203 || roomNum === 1303 || roomNum === 1403) {
                        return <div className="room_type_view">
                        <img src={Czumen} />
                        <div className="space_small_module" />
                        <img src={Cmm} />
                    </div>
                    } else if(roomNum === 104 || roomNum === 204 || roomNum === 304 || roomNum === 404 || roomNum === 504 || roomNum === 604 || roomNum === 704 || roomNum === 804 || roomNum === 904 || roomNum === 1004 || roomNum === 1204 || roomNum === 1304 || roomNum === 1404) {
                        return <div className="room_type_view">
                        <img src={Dzumen} />
                        <div className="space_small_module" />
                        <img src={Dmm} />
                    </div>
                    } else if(roomNum === 105 || roomNum === 205 || roomNum === 305 || roomNum === 405 || roomNum === 505 || roomNum === 605 || roomNum === 705 || roomNum === 805 || roomNum === 905 || roomNum === 1005 || roomNum === 1205 || roomNum === 1305 || roomNum === 1405) {
                        return <div className="room_type_view">
                        <img src={Ezumen} />
                        <div className="space_small_module" />
                        <img src={Emm} />
                    </div>
                    } else if(roomNum === 106 || roomNum === 206 || roomNum === 306 || roomNum === 406 || roomNum === 506 || roomNum === 606 || roomNum === 706 || roomNum === 806 || roomNum === 906 || roomNum === 1006 || roomNum === 1206 || roomNum === 1306 || roomNum === 1406) {
                        return <div className="room_type_view">
                        <img src={Azumen} />
                        <div className="space_small_module" />
                        <img src={Amm} />
                    </div>
                    } else {
                        return <div className="room_type_view">
                        <img src={Azumen} />
                        <div className="space_small_module" />
                        <img className="room_type_tab" src={Amm} />
                    </div>
                    }
                })()}
                {showRoomNumpad === 1 && (
                    <div className="room_pad">
                        <TenKey handleInputClose={handleInputClose} handleClose={handleClose} handleClick={handleClick} />
                    </div>
                )}
                {showRoomNumpad === 2 && (
                    <div className="rate_pad">
                        <TenKey handleInputClose={handleInputClose} handleClose={handleClose} handleClick={handleClick} />
                    </div>
                )}
                {showRoomNumpad === 3 && (
                    <div className="payment_pad">
                        <TenKey handleInputClose={handleInputClose} handleClose={handleClose} handleClick={handleClick} />
                    </div>
                )}
                {showRoomNumpad === 4 && (
                    <div className="period_pad">
                        <TenKey handleInputClose={handleInputClose} handleClose={handleClose} handleClick={handleClick} />
                    </div>
                )}
                {showRoomNumpad === 5 && (
                    <div className="bonus_pad">
                        <TenKey handleInputClose={handleInputClose} handleClose={handleClose} handleClick={handleClick} />
                    </div>
                )}
            </div>
            <div className="room_simulation">
                <div className="room_menu">
                    <MenuList id={id} />
                </div>
                <div className="simulate_form">
                    <div className="simulate_form1">
                        <div className="simulatation_form">
                            <p>ROOM No.</p>
                            <input readonly="readonly" onClick={() => handleInputClick(1)} value={roomNum} onChange={inputRoomNum} name="field1" onFocus={handleFocus} ref={roomNumRef} />
                        </div>
                        <div className="simulatation_form">
                            <p>分譲価格</p>
                            <h2>{roomPrice[index]}<span>万円</span></h2>
                        </div>
                    </div>
                    <div className="simulate_form1">
                        <div className="simulatation_form">
                            <p>金利</p>
                            <input onClick={() => handleInputClick(2)} readonly="readonly" value={rate} onChange={inputRate} name="field2" onFocus={handleFocus} ref={rateRef} />
                            <span>%</span>
                        </div>
                        <div className="simulatation_form">
                            <p>頭金</p>
                            <input onClick={() => handleInputClick(3)}  readonly="readonly"  step="10" value={payment} onChange={inputPayment} name="field3" onFocus={handleFocus} ref={paymentRef} /><span>万円</span>
                        </div>
                    </div>
                    <div className="simulate_form1">
                        <div className="simulatation_form">
                            <p>返済期間</p>
                            <input onClick={() => handleInputClick(4)} readonly="readonly" value={period} onChange={inputPeriod} name="field4" onFocus={handleFocus} ref={periodRef} /><span>年</span>
                        </div>
                        <div className="simulatation_form">
                            <p>ボーナス月加算</p>
                            <input onClick={() => handleInputClick(5)} readonly="readonly" step="10" value={bonus} onChange={inputBonus} name="field5" onFocus={handleFocus} ref={bonusRef} /><span>万円</span>
                        </div>
                    </div>
                    <div className="total_form">
                        <p>毎月お支払額</p>
                        <input value={Math.ceil(((roomPrice[index]*10000+(roomPrice[index]*10000*rate)) - payment*10000 - (bonus*10000*2*period)) / (period*12))} onChange={inputMonth} /><span>円</span>
                    </div>
                    <button onClick={() => handleInputClose()}>Close TenKey</button>
                </div>
            </div>
        </div>
    </div>
    )
}

export default Simulation;