import React from "react";
import { useState } from "react";
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import Garally1 from "../img/garally/garally1.jpg";
import Garally2 from "../img/garally/garally2.jpg";
import Garally3 from "../img/garally/garally3.jpg";
import Garally4 from "../img/garally/garally4.jpg";
import Garally5 from "../img/garally/garally5.jpg";
import Garally6 from "../img/garally/garally6.jpg";
import Garally7 from "../img/garally/garally7.jpg";
import Garally8 from "../img/garally/garally8.jpg";
import Garally9 from "../img/garally/garally9.jpg";
import Thumb1 from "../img/thumb/thumb1.jpg";
import Thumb2 from "../img/thumb/thumb2.jpg";
import Thumb3 from "../img/thumb/thumb3.jpg";
import Thumb4 from "../img/thumb/thumb4.jpg";
import Thumb5 from "../img/thumb/thumb5.jpg";
import Thumb6 from "../img/thumb/thumb6.jpg";
import Thumb7 from "../img/thumb/thumb7.jpg";
import Thumb8 from "../img/thumb/thumb8.jpg";
import Thumb9 from "../img/thumb/thumb9.jpg";
import { useRef } from "react";

const CGgarally = () => {
    const [toggle, setToggle] = useState(0)
    const swipeRef = useRef(null);
    const changeSlide = (index) => {
        if (swipeRef.current && swipeRef.current.swiper) {
            swipeRef.current.swiper.slideTo(index+1)
            setToggle(index+1)
        }
    };
    const imageList = [
        {index: 0, image: Garally5},
        {index: 1, image: Garally2},
        {index: 2, image: Garally3},
        {index: 3, image: Garally4},
        {index: 4, image: Garally1},
        {index: 5, image: Garally6},
        {index: 6, image: Garally7},
        {index: 7, image: Garally8},
        {index: 8, image: Garally9},
    ]
    return (
        <div className="garally_page">
            <h2>CG GARALLY</h2>
            <div className="garally_root">
                <div className="thumbnail">
                    <div className="thumbnail1">
                        <img className={toggle === 1 ? "active_thumbnail" : ""} onClick={() => changeSlide(0)} src={Thumb5} />
                    </div>
                    <div className="thumbnail2">
                        <img className={toggle === 2 ? "active_thumbnail" : ""} onClick={() => changeSlide(1)} src={Thumb2} />
                        <img className={toggle === 3 ? "active_thumbnail" : ""} onClick={() => changeSlide(2)} src={Thumb3} />
                    </div>
                    <div className="thumbnail2">
                        <img className={toggle === 4 ? "active_thumbnail" : ""} onClick={() => changeSlide(3)} src={Thumb4} />
                        <img className={toggle === 5 ? "active_thumbnail" : ""} onClick={() => changeSlide(4)} src={Thumb1} />
                    </div>
                    <div className="thumbnail2">
                        <img className={toggle === 6 ? "active_thumbnail" : ""} onClick={() => changeSlide(5)} src={Thumb6} />
                        <img className={toggle === 7 ? "active_thumbnail" : ""} onClick={() => changeSlide(6)} src={Thumb7} />
                    </div>
                    <div className="thumbnail2">
                        <img className={toggle === 8 ? "active_thumbnail" : ""} onClick={() => changeSlide(7)} src={Thumb8} />
                        <img className={toggle === 9 ? "active_thumbnail" : ""} onClick={() => changeSlide(8)} src={Thumb9} />
                    </div>
                </div>
                <div className="garally_view">
                    <Swiper 
                        ref={swipeRef}
                        initialSlide={imageList[0].index}
                        slidesPerView={1}
                        loop={true}
                        className="swiper_page"
                        onSlideChange={(swiper) => changeSlide(swiper.realIndex)}
                    >
                        {imageList.map((image) => (
                            <SwiperSlide className={image.index === 1 ? "active_garally_img_virtical" : "active_garally_img"} key={image.index}>
                                <img src={image.image} />
                            </SwiperSlide>
                        ))}
                    </Swiper> 
                </div>
            </div>
        </div>
    )
}

export default CGgarally;