import React from "react";

const TenKey = (props) => {
    return(
        <div className="ten_key">
            <button onClick={() => props.handleClick(1)}>1</button>
            <button onClick={() => props.handleClick(2)}>2</button>
            <button onClick={() => props.handleClick(3)}>3</button>
            <br />
            <button onClick={() => props.handleClick(4)}>4</button>
            <button onClick={() => props.handleClick(5)}>5</button>
            <button onClick={() => props.handleClick(6)}>6</button>
            <br />
            <button onClick={() => props.handleClick(7)}>7</button>
            <button onClick={() => props.handleClick(8)}>8</button>
            <button onClick={() => props.handleClick(9)}>9</button>
            <br />
            <button onClick={() => props.handleClick(0)}>0</button>
            <button onClick={() => props.handleClick(".")}>.</button>
            <button onClick={() => props.handleClose()}>×</button>
            <br />
            <button onClick={() => props.handleClose()}>C</button>
            <button onClick={() => props.handleInputClose()}>Enter</button>
        </div>
    )
}

export default TenKey;