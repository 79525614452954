import React from "react";
import { Link, useNavigate } from "react-router-dom";
import SiteText from "../img/floorplan/siteplan.png"
import ElevationText from "../img/floorplan/elevationplan.png"
import PlanText from "../img/floorplan/floorplan.png"

const FloorPlanMenu = (props) => {
    const navigate = useNavigate();
    const id = props.id
    return(
        <div className="floor_paln_menu">
            <button onClick={() => navigate('/roomplan')} className={id === "roomplan" ? "froor_plan_button active" : "froor_plan_button"}>
                <img src={PlanText} />
            </button>
            <button onClick={() => navigate('/siteplan')} className={id === "siteplan" ? "froor_plan_button active" : "froor_plan_button"}>
                <img src={SiteText} />
            </button>
            <button onClick={() => navigate('/elevationplan')} className={id === "elevationplan" ? "froor_plan_button active" : "froor_plan_button"}>
                <img src={ElevationText} />
            </button>
        </div>
    )
}

export default FloorPlanMenu;