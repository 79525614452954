import React from "react";
import BankB from "../img/icon_single/bank.png"
import BusB from "../img/icon_single/busstop.png"
import EduB from "../img/icon_single/education.png"
import MedB from "../img/icon_single/medical.png"
import PubB from "../img/icon_single/public.png"
import RailB from "../img/icon_single/railway.png"
import RestB from "../img/icon_single/restaurant.png"
import ShopB from "../img/icon_single/shopping.png"
import MapMenuButtonList from "../component/MapMenuButtonList";
import MapView from "../img/icon_single/map_2.jpg"

const AerialShoot = () => {
    const path = window.location.href
    const id = path.split('/#/')[1]

    return (
        <div className="area_root"> 
            <div className="map">
                <div className="map_view_image">
                    <h2>AERIAL SHOOT</h2>
                </div>
                <div className="map_sub_menu">
                    <div className="map_button_root stealth">
                        <div className="map_button">
                            <div className="map_button_item ed active">
                                <img src={EduB} />
                                <div className="map_border ed" />
                                <p>EDUCATION</p>
                            </div>
                            <div className="map_button_item me active">
                                <img src={MedB} />
                                <div className="map_border me" />
                                <p>MEDICAL</p>
                            </div>
                        </div>
                        <div className="map_button">
                            <div className="map_button_item sh active">
                                <img src={ShopB} />
                                <div className="map_border sh" />
                                <p>SHOPPING</p>
                            </div>
                            <div className="map_button_item ra active">
                                <img src={RailB} />
                                <div className="map_border ra"  />
                                <p>RESTAURANT</p>
                            </div>
                        </div>
                        <div className="map_button">
                            <div className="map_button_item bs active">
                                <img src={BusB} />
                                <div className="map_border bs"  />
                                <p>RAIL WAY</p>
                            </div>
                            <div className="map_button_item re active">
                                <img src={RestB} />
                                <div className="map_border re" />
                                <p>BUS STOP</p>
                            </div>
                        </div>
                        <div className="map_button">
                            <div className="map_button_item ba active">
                                <img src={BankB} />
                                <div className="map_border ba"  />
                                <p>BANK</p>
                            </div>
                            <div className="map_button_item pu active">
                                <img src={PubB} />
                                <div className="map_border pu"  />
                                <p>PUBLIC</p>
                            </div>
                        </div>
                    </div>
                    <div className="space_small_module" />
                    <MapMenuButtonList id={id} />
                </div>
            </div>
        </div>
    )
}

export default AerialShoot;