import React, { useState } from "react";
import Title from '../img/roomplan_sozai2/roomplan_title.png'
import Azumen from '../img/room_sozai/A_zu.png'
import Amm from '../img/roomplan_sozai2/Amm.png'
import Abath from '../img/Asozai/a_bath.jpg'
import Abed from '../img/Asozai/a_bed.jpg'
import Acrasic from '../img/Asozai/a_carasic.jpg'
import Ajapan from '../img/Asozai/a_japan.jpg'
import Aliving from '../img/Asozai/a_living.jpg'
import Aterace from '../img/Asozai/a_terace.jpg'
import Awash from '../img/Asozai/a_wash.jpg'
import Ashoose from '../img/Asozai/a_shoose.jpg'
import TypeButton from "../component/TypeButton";
import MenuList from "../component/MenuList";
import { useNavigate } from "react-router-dom";
import FloorPlanMenu from "../component/FloorPlanMenu";

const RoomPlanA = () => {
  const path = window.location.href
  const id = path.split('/#/')[1]
  const [imgToggle, setImgToggle] = useState(1);
  const [planToggle, setPlanToggle] = useState(0);
  const imgToggleTab = (index) => {
    setImgToggle(index);
  };
  return(
    <div className="root">
      <div className="main_container">
        <div className='room_plan'>
          <h2>ROOM PLAN</h2>
          <div>
            <div className='room_type_view'>
              <img src={Azumen} />
              <div onClick={() => imgToggleTab(8)} className={imgToggle === 8 ? "active_point1" : "point1"} />
              <div onClick={() => imgToggleTab(2)} className={imgToggle === 2 ? "active_point2" : "point2"} />
              <div onClick={() => imgToggleTab(3)} className={imgToggle === 3 ? "active_point3" : "point3"} />
              <div onClick={() => imgToggleTab(5)} className={imgToggle === 5 ? "active_point5" : "point5"} />
              <div onClick={() => imgToggleTab(6)} className={imgToggle === 6 ? "active_point6" : "point6"} />
              <div onClick={() => imgToggleTab(7)} className={imgToggle === 7 ? "active_point7" : "point7"} />
              <div onClick={() => imgToggleTab(1)} className={imgToggle === 1 ? "active_point8" : "point8"} />
              <div onClick={() => imgToggleTab(9)} className={imgToggle === 9 ? "active_point9" : "point9"} />
            </div>
            <div className="space_small_module" />
            <img className="room_type_tab" src={Amm} />
          </div>
        </div>
        <div className='room_view'>
          <div className="room_menu">
            <MenuList id={id} />
            <div className="space_small_module" />
            <TypeButton id={id} />
            <div className="space_small_module" />
            <FloorPlanMenu planToggle={planToggle} setPlanToggle={setPlanToggle} />
          </div>
          <div className="space_small_module" />
          <div className="type_room">
            <div className={imgToggle === 8 ? "active_content" : "contet"}>
              <img src={Ashoose} />
              <p>靴箱</p>
            </div>
            <div className={imgToggle === 2 ? "active_content" : "contet"}>
              <img src={Acrasic} />
              <p>寝室</p>
            </div>
            <div className={imgToggle === 3 ? "active_content" : "contet"}>
              <img src={Abed} />
              <p>寝室 16.5畳</p>
            </div>
            <div className={imgToggle === 4 ? "active_content" : "contet"}>
              <img src={Acrasic} />
              <p>寝室 16.6畳</p>
            </div>
            <div className={imgToggle === 5 ? "active_content" : "contet"}>
              <img src={Abath} />
              <p>浴室</p>
            </div>
            <div className={imgToggle === 6 ? "active_content" : "contet"}>
              <img src={Awash} />
              <p>洗面所</p>
            </div>
            <div className={imgToggle === 7 ? "active_content" : "contet"}>
              <img src={Ajapan} />
              <p>和室</p>
            </div>
            <div className={imgToggle === 1 ? "active_content" : "contet"}>
              <img src={Aliving} />
              <p>リビング</p>
            </div>
            <div className={imgToggle === 9 ? "active_content" : "contet"}>
              <img src={Aterace} />
              <p>バルコニー</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
}

export default RoomPlanA;