import React, { useState } from "react";
import Atype from "../component/TypeBox/Atype";
import Btype from "../component/TypeBox/Btype";
import Ctype from "../component/TypeBox/Ctype";


const ToggleRoom = () => {
    const [roomToggle, setRoomToggle] = useState(1);
    const [typeToggle, setTypeToggle] = useState(1);
    const [versionToggle, setVersionToggle] = useState(1);
    const [typeToggle2, setTypeToggle2] = useState(1);
    const [versionToggle2, setVersionToggle2] = useState(1);

    const changeRoomToggle = (index) => {
        setRoomToggle(index)
    }
    const changeTypeToggle = (index) => {
        setTypeToggle(index)
    }
    const changeVirsionToggle = (index) => {
        setVersionToggle(index)
    }
    const changeTypeToggle2 = (index) => {
        setTypeToggle2(index)
    }
    const changeVirsionToggle2 = (index) => {
        setVersionToggle2(index)
    }
    return(
        <div className="root" style={{backgroundColor: "#fff"}}>
            <div className="container">
                <div className="toggle_container">
                    <div className="toggle_type_button">
                        <div 
                            onClick={() => changeTypeToggle(1)} 
                            className={typeToggle === 1 ? "type_button_item active" : "type_button_item"}
                        >
                            <button>A1 type</button>
                        </div>
                        <div 
                            onClick={() => changeTypeToggle(2)} 
                            className={typeToggle === 2 ? "type_button_item active" : "type_button_item"}
                        >
                            <button>B1 type</button>
                        </div>
                        <div 
                            onClick={() => changeTypeToggle(3)} 
                            className={typeToggle === 3 ? "type_button_item active" : "type_button_item"}
                        >
                            <button>C1 type</button>
                        </div>
                    </div>
                    <div className={typeToggle === 1 ? "room_type_toggle_img_container" : "room_type_toggle_img_container none"}>
                        <Atype roomToggle={roomToggle} versionToggle={versionToggle} />
                    </div>
                    <div className={typeToggle === 2 ? "room_type_toggle_img_container" : "room_type_toggle_img_container none"}>
                        <Btype roomToggle={roomToggle} versionToggle={versionToggle} />
                    </div>
                    <div className={typeToggle === 3 ? "room_type_toggle_img_container" : "room_type_toggle_img_container none"}>
                        <Ctype roomToggle={roomToggle} versionToggle={versionToggle} />
                    </div>
                    <div className="toggle_type_button">
                        <div 
                            onClick={() => changeVirsionToggle(1)} 
                            className={versionToggle === 1 ? "type_button_item active" : "type_button_item"}
                        >
                            <button>Normal</button>
                        </div>
                        <div 
                            onClick={() => changeVirsionToggle(2)} 
                            className={versionToggle === 2 ? "type_button_item active" : "type_button_item"}
                        >
                            <button>Scale</button>
                        </div>
                        <div 
                            onClick={() => changeVirsionToggle(3)} 
                            className={versionToggle === 3 ? "type_button_item active" : "type_button_item"}
                        >
                            <button>Image</button>
                        </div>
                    </div>
                </div>
                <div className="toggle_container reverse">
                    <div className="toggle_type_button">
                        <div 
                            onClick={() => changeTypeToggle2(1)} 
                            className={typeToggle2 === 1 ? "type_button_item active" : "type_button_item"}
                        >
                            <button>A1 type</button>
                        </div>
                        <div 
                            onClick={() => changeTypeToggle2(2)} 
                            className={typeToggle2 === 2 ? "type_button_item active" : "type_button_item"}
                        >
                            <button>B1 type</button>
                        </div>
                        <div 
                            onClick={() => changeTypeToggle2(3)} 
                            className={typeToggle2 === 3 ? "type_button_item active" : "type_button_item"}
                        >
                            <button>C1 type</button>
                        </div>
                    </div>
                    <div 
                        className={typeToggle2 === 1 ? "room_type_toggle_img_container" : "room_type_toggle_img_container none"}
                    >
                        <Atype roomToggle={roomToggle} versionToggle={versionToggle2} />
                    </div>
                    <div className={typeToggle2 === 2 ? "room_type_toggle_img_container" : "room_type_toggle_img_container none"}>
                        <Btype roomToggle={roomToggle} versionToggle={versionToggle2} />
                    </div>
                    <div className={typeToggle2 === 3 ? "room_type_toggle_img_container" : "room_type_toggle_img_container none"}>
                        <Ctype roomToggle={roomToggle} versionToggle={versionToggle2} />
                    </div>
                    <div className="toggle_type_button">
                        <div 
                            onClick={() => changeVirsionToggle2(1)} 
                            className={versionToggle2 === 1 ? "type_button_item active" : "type_button_item"}
                        >
                            <button>Normal</button>
                        </div>
                        <div 
                            onClick={() => changeVirsionToggle2(2)} 
                            className={versionToggle2 === 2 ? "type_button_item active" : "type_button_item"}
                        >
                            <button>Scale</button>
                        </div>
                        <div 
                            onClick={() => changeVirsionToggle2(3)} 
                            className={versionToggle2 === 3 ? "type_button_item active" : "type_button_item"}
                        >
                            <button>Image</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="room_toggle_container">
                <div 
                    onClick={() => changeRoomToggle(1)}
                    className={roomToggle === 1 ? "room_toggle_container_item active" : "room_toggle_container_item"}
                >
                    <button>Entrance</button>
                </div>
                <div 
                    onClick={() => changeRoomToggle(2)}
                    className={roomToggle === 2 ? "room_toggle_container_item active" : "room_toggle_container_item"}
                >
                    <button>Living</button>
                </div>
                <div 
                    onClick={() => changeRoomToggle(3)}
                    className={roomToggle === 3 ? "room_toggle_container_item active" : "room_toggle_container_item"}
                >
                    <button>Bedroom1</button>
                </div>
                <div 
                    onClick={() => changeRoomToggle(4)}
                    className={roomToggle === 4 ? "room_toggle_container_item active" : "room_toggle_container_item"}
                >
                    <button>Bedroom2</button>
                </div>
                <div 
                    onClick={() => changeRoomToggle(5)}
                    className={roomToggle === 5 ? "room_toggle_container_item active" : "room_toggle_container_item"}
                >
                    <button>Bedroom3</button>
                </div>
                <div 
                    onClick={() => changeRoomToggle(6)}
                    className={roomToggle === 6 ? "room_toggle_container_item active" : "room_toggle_container_item"}
                >
                    <button>Bathroom</button>
                </div>
                <div 
                    onClick={() => changeRoomToggle(7)}
                    className={roomToggle === 7 ? "room_toggle_container_item active" : "room_toggle_container_item"}
                >
                    <button>Lavatory</button>
                </div>
                <div 
                    onClick={() => changeRoomToggle(8)}
                    className={roomToggle === 8 ? "room_toggle_container_item active" : "room_toggle_container_item"}
                >
                    <button>Powderroom</button>
                </div>
                <div 
                    onClick={() => changeRoomToggle(9)}
                    className={roomToggle === 9 ? "room_toggle_container_item active" : "room_toggle_container_item"}
                >
                    <button>W.I.C</button>
                </div>
            </div>
        </div>
    )
}

export default ToggleRoom;