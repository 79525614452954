import React, { useState } from "react";
import ColorSelectNav from "../../component/ColorSelectNav";
import BathRoomImage1 from "../../img/color_select1/erathblack.jpg"
import BathRoomImage2 from "../../img/color_select1/maple.jpg"
import BathRoomImage3 from "../../img/color_select1/dark.jpg"
import BathRoomImage4 from "../../img/color_select1/wood.jpg"
import Bath1 from "../../img/color_select1/bath1ico.jpg"
import Bath2 from "../../img/color_select1/bath2ico.jpg"
import Bath3 from "../../img/color_select1/bath3ico.jpg"
import Bath4 from "../../img/color_select1/bath4ico.jpg"

const BathRoom = () => {
    const path = window.location.href
    const id = path.split('/#/')[1]
    const [panelToggle, setPanelToggle] = useState(1);
    const panelToggleTab = (index) => {
        setPanelToggle(index);
    };
    return(
        <div className="color_select_root">
            <div className="select_view_bath">
                <img className={panelToggle === 1 ? "panel_show active" : "panel_show"} src={BathRoomImage1} />
                <img className={panelToggle === 2 ? "panel_show active" : "panel_show"} src={BathRoomImage2} />
                <img className={panelToggle === 3 ? "panel_show active" : "panel_show"} src={BathRoomImage3} />
                <img className={panelToggle === 4 ? "panel_show active" : "panel_show"} src={BathRoomImage4} />
            </div>
            <div className="select_menu">
                <div>
                    <div className="space_large_module"/>
                    <p>BATHROOM PANEL</p>
                    <div className="slect_plat">
                        <img onClick={() => panelToggleTab(1)} src={Bath1} />
                        <img onClick={() => panelToggleTab(2)} src={Bath2} />
                        <img onClick={() => panelToggleTab(3)} src={Bath3} />
                        <img onClick={() => panelToggleTab(4)} src={Bath4} />
                    </div>
                </div>
                <ColorSelectNav id={id} />
            </div>
        </div>
    )
}

export default BathRoom;
