import React from "react";
import CtypeEntrance from "../../img/Ctype/C1_entrance.jpg";
import CtypeLiving from "../../img/Ctype/C1_Living.jpg";
import CtypeBed1 from "../../img/Ctype/C1_bed1.jpg";
import CtypeBed2 from "../../img/Ctype/C1_bed2.jpg";
import CtypeBed3 from "../../img/Ctype/C1_bed3.jpg";
import CtypeBath from "../../img/Ctype/C1_bath.jpg";
import CtypeLav from "../../img/Ctype/C1_lavatory.jpg";
import CtypePowder from "../../img/Ctype/C1_powder.jpg";
import CtypeWIC from "../../img/Ctype/C1_WIC.jpg";
import CtypeSEntarnce from "../../img/Ctype/C1_s_entrance.jpg";
import CtypeSLiving from "../../img/Ctype/C1_s_living.jpg";
import CtypeSBed1 from "../../img/Ctype/C1_s_bed1.jpg";
import CtypeSBed2 from "../../img/Ctype/C1_s_bed2.jpg";
import CtypeSBed3 from "../../img/Ctype/C1_s_bed3.jpg";
import CtypeSBath from "../../img/Ctype/C1_s_bath.jpg";
import CtypeSLav from "../../img/Ctype/C1_s_lavatory.jpg";
import CtypeSPowder from "../../img/Ctype/C1_s_powder.jpg";
import CtypeSWIC from "../../img/Ctype/C1_s_WIC.jpg";
import CtypeIEntrance from "../../img/Ctype/C1_i_entrance.jpg";
import CtypeILiving from "../../img/Ctype/C1_i_living.jpg";
import CtypeIBed1 from "../../img/Ctype/C1_i_bed1.jpg";
import CtypeIBed2 from "../../img/Ctype/C1_i_bed2.jpg";
import CtypeIBed3 from "../../img/Ctype/C1_i_bed3.jpg";
import CtypeIBath from "../../img/Ctype/C1_i_bath.jpg";
import CtypeILav from "../../img/Ctype/C1_i_lavatory.jpg";
import CtypeIPowder from "../../img/Ctype/C1_i_powder.jpg";
import CtypeIWIC from "../../img/Ctype/C1_i_WIC.jpg";

const Ctype = (props) => {
    const roomToggle = props.roomToggle;
    const versionToggle = props.versionToggle;
    return(
        <div className="room_type_toogle_img_frame">
            <div className={roomToggle === 1 ? "room_type_toogle_img_item" : "room_type_toogle_img_item none"}>
                <div className={versionToggle === 1 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={CtypeEntrance} />
                </div>
                <div className={versionToggle === 2 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={CtypeSEntarnce} />
                </div>
                <div className={versionToggle === 3 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={CtypeIEntrance} />
                </div>
            </div>
            <div className={roomToggle === 2 ? "room_type_toogle_img_item" : "room_type_toogle_img_item none"}>
                <div className={versionToggle === 1 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={CtypeLiving} />
                </div>
                <div className={versionToggle === 2 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={CtypeSLiving} />
                </div>
                <div className={versionToggle === 3 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={CtypeILiving} />
                </div>
            </div>
            <div className={roomToggle === 3 ? "room_type_toogle_img_item" : "room_type_toogle_img_item none"}>
                <div className={versionToggle === 1 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={CtypeBed1} />
                </div>
                <div className={versionToggle === 2 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={CtypeSBed1} />
                </div>
                <div className={versionToggle === 3 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={CtypeIBed1} />
                </div>
            </div>
            <div className={roomToggle === 4 ? "room_type_toogle_img_item" : "room_type_toogle_img_item none"}>
                <div className={versionToggle === 1 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={CtypeBed2} />
                </div>
                <div className={versionToggle === 2 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={CtypeSBed2} />
                </div>
                <div className={versionToggle === 3 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={CtypeIBed2} />
                </div>
            </div>
            <div className={roomToggle === 5 ? "room_type_toogle_img_item" : "room_type_toogle_img_item none"}>
                <div className={versionToggle === 1 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={CtypeBed3} />
                </div>
                <div className={versionToggle === 2 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={CtypeSBed3} />
                </div>
                <div className={versionToggle === 3 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={CtypeIBed3} />
                </div>
            </div>
            <div className={roomToggle === 6 ? "room_type_toogle_img_item" : "room_type_toogle_img_item none"}>
                <div className={versionToggle === 1 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={CtypeBath} />
                </div>
                <div className={versionToggle === 2 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={CtypeSBath} />
                </div>
                <div className={versionToggle === 3 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={CtypeIBath} />
                </div>
            </div>
            <div className={roomToggle === 7 ? "room_type_toogle_img_item" : "room_type_toogle_img_item none"}>
                <div className={versionToggle === 1 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={CtypeLav} />
                </div>
                <div className={versionToggle === 2 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={CtypeSLav} />
                </div>
                <div className={versionToggle === 3 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={CtypeILav} />
                </div>
            </div>
            <div className={roomToggle === 8 ? "room_type_toogle_img_item" : "room_type_toogle_img_item none"}>
                <div className={versionToggle === 1 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={CtypePowder} />
                </div>
                <div className={versionToggle === 2 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={CtypeSPowder} />
                </div>
                <div className={versionToggle === 3 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={CtypeIPowder} />
                </div>
            </div>
            <div className={roomToggle === 9 ? "room_type_toogle_img_item" : "room_type_toogle_img_item none"}>
                <div className={versionToggle === 1 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={CtypeWIC} />
                </div>
                <div className={versionToggle === 2 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={CtypeSWIC} />
                </div>
                <div className={versionToggle === 3 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={CtypeIWIC} />
                </div>
            </div>
        </div>
    )
}

export default Ctype;