import React, { useContext, useEffect, useState } from "react";
import GlobalContext from "../context/GlobalContext";
import { getMonth } from "../utils/FunctionPac";
import { collection, onSnapshot, query } from "firebase/firestore";
import { db } from "../firebase";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from 'moment';
import 'moment/locale/ja';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);

const formats = {
    dateFormat: 'D',
    dayFormat: 'D(ddd)',
    monthHeaderFormat: 'YYYY年M月',
    dayHeaderFormat: 'M月D日(ddd)',
    dayRangeHeaderFormat: 'YYYY年M月',
}

const Carender = () => {
    const [currentMonth, setCurrentMonth] = useState(getMonth());
    const { monthIndex, showEventModal } = useContext(GlobalContext);
    const [events, setEvents] = useState([]);

    useEffect(() => {
        const eventRef = collection(db, 'apartment', 'sIXBcOFB9TRMxTh8vI5b', 'events');
        const q = query(eventRef);
        onSnapshot(q, snapshots => {
            const events = snapshots.docs.map((doc) => {
                const data = doc.data();
                return {
                    id: doc.id,
                    title: data.title,
                    start: data.date.toDate(),
                    end: data.date.toDate()
                }
            })
            setEvents(events);
        })
    }, [])

    useEffect(() => {
        setCurrentMonth(getMonth(monthIndex));
    }, [monthIndex]);

    return(
        <div className="carender_root">
            <h1>CALENDAR</h1>
            <div className="carender">
                <Calendar
                    localizer={localizer}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    style={{height: "80vh"}}
                    formats={formats}
                />
            </div>
        </div>
    )
}

export default Carender;