import React from "react";

const RoomNum = (props) => {
    const doc = props.doc
    const setRoomNum = props.setRoomNum;
    const setImgToggle = props.setImgToggle;
    const imgToggleTab = (type, num) => {
        setImgToggle(type);
        setRoomNum(num)
    };
    return (
        <div className="room_list_table">
            {doc.map((room) => (
                <div onClick={() => imgToggleTab(room.type, room.num)} className="room_box">
                    <div className="room_box1">
                        <p>{room.num}</p>
                        <span>{room.room}</span>
                    </div>
                    {(() => {
                        if(room.status === "vacancy") { //公開
                            return(
                                <>
                                    <div className={doc.reserve === true ? "room_box2 reserve" : "room_box2"}>
                                        <p>{room.type}</p>
                                    </div>
                                    <div className={doc.reserve === true ? "room_box2 reserve" : "room_box3"}>
                                        <p>{room.price}万円</p>
                                    </div>
                                </>
                            )
                        } else if(room.status === "negotiation") {
                            return(
                                <>
                                    <div className={doc.reserve === true ? "room_box2 reserve" : "room_box2"}>
                                        <p>商談中</p>
                                        <br />
                                        <br />
                                    </div>
                                </>
                            )
                        } else if(room.status === "contact") {
                            return(
                                <>
                                    <div className={doc.reserve === true ? "room_box2 reserve" : "room_box2"}>
                                        <p>契約済</p>
                                        <br />
                                        <br />
                                    </div>
                                </>
                            )
                        } else if(room.status === "application") {
                            return(
                                <>
                                    <div className={doc.reserve === true ? "room_box2 reserve" : "room_box2"}>
                                        <p>申込済</p>
                                        <br />
                                        <br />
                                    </div>
                                </>
                            )
                        } else if(room.status === "unpublished") {
                            return(
                                <>
                                    <div className={doc.reserve === true ? "room_box2 reserve" : "room_box2"}>
                                        <p>未公開</p>
                                        <br />
                                        <br />
                                    </div>
                                </>
                            )
                        } else if(room.status === "nextSale") {
                            return(
                                <>
                                    <div className={doc.reserve === true ? "room_box2 reserve" : "room_box2"}>
                                        <p>次期分譲</p>
                                        <br />
                                        <br />
                                    </div>
                                </>
                            )
                        }
                    })()}
                </div>
            ))}
        </div>
    )
}

export default RoomNum;