import { GoogleMap, InfoWindow, Marker, useLoadScript } from "@react-google-maps/api";
import React, { useRef, useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Hm5 from "../img/hazardmap _parts/hm5.jpg"
import BankB from "../img/icon_single/bank.png"
import BusB from "../img/icon_single/busstop.png"
import EduB from "../img/icon_single/education.png"
import MedB from "../img/icon_single/medical.png"
import PubB from "../img/icon_single/public.png"
import RailB from "../img/icon_single/railway.png"
import RestB from "../img/icon_single/restaurant.png"
import ShopB from "../img/icon_single/shopping.png"
import MapStyle from "../component/MapStyle"
import Gaikan from "../img/map icon/gaikan-icon.jpg"
import MapMenuButtonList from "../component/MapMenuButtonList";

const containerStyle = {
    width: '100%',
    height: '85vh'
};
const options = {
    styles: MapStyle,
    zoomControl: true,
    disableDefaultUI: false,
}
const libraries = ["places"];

const HazardMap2 = () => {
    const path = window.location.href
    const id = path.split('/#/')[1]
    const navigate = useNavigate()
    const [center, setCenter] = useState({lat: "", lng: ""});
    const [selectedPin, setSelectedPin] = useState(null);
    const [selectedPin1, setSelectedPin1] = useState(null);
    const [selectedPin2, setSelectedPin2] = useState(null);
    const [selectedPin3, setSelectedPin3] = useState(null);
    const [selectedPin4, setSelectedPin4] = useState(null);
    const [selectedPin5, setSelectedPin5] = useState(null);
    const [selectedPin6, setSelectedPin6] = useState(null);
    const [selectedPin7, setSelectedPin7] = useState(null);
    const [selectedPin8, setSelectedPin8] = useState(null);
    const [selectedPin9, setSelectedPin9] = useState(null);
    const [selectedPin10, setSelectedPin10] = useState(null);
    const [selectedPin11, setSelectedPin11] = useState(null);
    const [selectedPin12, setSelectedPin12] = useState(null);
    const [selectedPin13, setSelectedPin13] = useState(null);
    const [selectedPin14, setSelectedPin14] = useState(null);
    const [selectedPin15, setSelectedPin15] = useState(null);
    const [selectedPin16, setSelectedPin16] = useState(null);
    const [selectedPin17, setSelectedPin17] = useState(null);
    const [selectedPin18, setSelectedPin18] = useState(null);
    const [selectedPin19, setSelectedPin19] = useState(null);
    const [selectedPin20, setSelectedPin20] = useState(null);
    const [selectedPin21, setSelectedPin21] = useState(null);
    const [selectedPin22, setSelectedPin22] = useState(null);

    const [tab1, setTab1] = useState(false)
    const [tab2, setTab2] = useState(false)
    const [tab3, setTab3] = useState(false)
    const [tab4, setTab4] = useState(false)
    const [tab5, setTab5] = useState(false)
    const [tab6, setTab6] = useState(false)
    const [tab7, setTab7] = useState(false)
    const [tab8, setTab8] = useState(false)
    const [tab9, setTab9] = useState(false)
    const [tab10, setTab10] = useState(false)
    const [tab11, setTab11] = useState(false)
    const [tab12, setTab12] = useState(false)
    const [tab13, setTab13] = useState(false)
    const [tab14, setTab14] = useState(false)
    const [tab15, setTab15] = useState(false)
    const [tab16, setTab16] = useState(false)
    const [tab17, setTab17] = useState(false)
    const [tab18, setTab18] = useState(false)
    const [tab19, setTab19] = useState(false)
    const [tab20, setTab20] = useState(false)
    const [tab21, setTab21] = useState(false)
    const [tab22, setTab22] = useState(false)
    const [tab23, setTab23] = useState(false)
    const [tab24, setTab24] = useState(false)
    const [tab25, setTab25] = useState(false)
    const [tab26, setTab26] = useState(false)
    const [tab27, setTab27] = useState(false)
    const [tab28, setTab28] = useState(false)
    const [tab29, setTab29] = useState(false)
    const [tab30, setTab30] = useState(false)
    const [tab31, setTab31] = useState(false)
    const [tab32, setTab32] = useState(false)
    const [tab33, setTab33] = useState(false)
    const [tab34, setTab34] = useState(false)
    const [tab35, setTab35] = useState(false)
    const [tab36, setTab36] = useState(false)
    const [tab37, setTab37] = useState(false)
    const [tab38, setTab38] = useState(false)
    const [tab39, setTab39] = useState(false)
    const [tab40, setTab40] = useState(false)
    const [tab41, setTab41] = useState(false)
    const [tab42, setTab42] = useState(false)
    const handleActiveMarker = (marker) => {
        if (marker === selectedPin) {
          return;
        }
        setSelectedPin(marker);
    };
    const handleActiveMarker1 = (marker) => {
        if (marker === selectedPin1) {
          return;
        }
        setSelectedPin2(marker);
    };
    const handleActiveMarker2 = (marker) => {
        if (marker === selectedPin2) {
          return;
        }
        setSelectedPin2(marker);
    };
    const handleActiveMarker3 = (marker) => {
        if (marker === selectedPin3) {
          return;
        }
        setSelectedPin3(marker);
    };
    const handleActiveMarker4 = (marker) => {
        if (marker === selectedPin4) {
          return;
        }
        setSelectedPin4(marker);
    };
    const handleActiveMarker5 = (marker) => {
        if (marker === selectedPin5) {
          return;
        }
        setSelectedPin5(marker);
    };
    const handleActiveMarker6 = (marker) => {
        if (marker === selectedPin6) {
          return;
        }
        setSelectedPin6(marker);
    };
    const handleActiveMarker7 = (marker) => {
        if (marker === selectedPin7) {
          return;
        }
        setSelectedPin7(marker);
    };
    const handleActiveMarker8 = (marker) => {
        if (marker === selectedPin8) {
          return;
        }
        setSelectedPin8(marker);
    };
    const handleActiveMarker9 = (marker) => {
        if (marker === selectedPin9) {
          return;
        }
        setSelectedPin9(marker);
    };
    const handleActiveMarker10 = (marker) => {
        if (marker === selectedPin10) {
          return;
        }
        setSelectedPin10(marker);
    };
    const handleActiveMarker11 = (marker) => {
        if (marker === selectedPin11) {
          return;
        }
        setSelectedPin11(marker);
    };
    const handleActiveMarker12 = (marker) => {
        if (marker === selectedPin12) {
          return;
        }
        setSelectedPin12(marker);
    };
    const handleActiveMarker13 = (marker) => {
        if (marker === selectedPin13) {
          return;
        }
        setSelectedPin13(marker);
    };
    const handleActiveMarker14 = (marker) => {
        if (marker === selectedPin14) {
          return;
        }
        setSelectedPin14(marker);
    };
    const handleActiveMarker15 = (marker) => {
        if (marker === selectedPin15) {
          return;
        }
        setSelectedPin15(marker);
    };
    const handleActiveMarker16 = (marker) => {
        if (marker === selectedPin16) {
          return;
        }
        setSelectedPin16(marker);
    };
    const handleActiveMarker17 = (marker) => {
        if (marker === selectedPin17) {
          return;
        }
        setSelectedPin17(marker);
    };
    const handleActiveMarker18 = (marker) => {
        if (marker === selectedPin18) {
          return;
        }
        setSelectedPin18(marker);
    };
    const handleActiveMarker19 = (marker) => {
        if (marker === selectedPin19) {
          return;
        }
        setSelectedPin19(marker);
    };
    const handleActiveMarker20 = (marker) => {
        if (marker === selectedPin20) {
          return;
        }
        setSelectedPin20(marker);
    };
    const handleActiveMarker21 = (marker) => {
        if (marker === selectedPin21) {
          return;
        }
        setSelectedPin21(marker);
    };
    const handleActiveMarker22 = (marker) => {
        if (marker === selectedPin22) {
          return;
        }
        setSelectedPin22(marker);
    };

    const {isLoaded, loadError} = useLoadScript({
        googleMapsApiKey: "AIzaSyCEBiv81fYKARpzoAf_dI_nP5jP1ngAlqY",
        libraries,
    })
    const mapRef = useRef();
    const onLoad = useCallback((map) => {
        mapRef.current = map;
    }, [])
    const success = data => {
        const currentPosition = {
            lat: 35.8164188797703,
            lng: 139.57796702650623,
        };
        setCenter(currentPosition);
    }
    const error = data => {
        const currentPosition = {
        lat: 35.81608428070906,
        lng: 139.57773709938652
        };
        setCenter(currentPosition);
    };
    useEffect(() => {
        navigator.geolocation.getCurrentPosition(success, error)
    },[])
    if(loadError) return "Error loading Maps"
    if(!isLoaded) return "Loading Maps"

    const pin = [
        {id: 0, position: {lat: 35.816117150215796, lng: 139.57764237669457}, name: "匠コーポレーション", minute: "現地から◯◯m", url: Gaikan}, //匠コーポレーション
    ]
    const pin1 = [
        {id: 1, position: {lat: 35.80146162563789, lng: 139.58236908220124}, name: "朝霞第一小学校", minute: "現地から◯◯m"}, 
    ]
    const pin2 = [
        {id: 2, position: {lat: 35.809556800055105, lng: 139.60436786870662}, name: "朝霞第二小学校", minute: "現地から◯◯m"}, 
    ]
    const pin3 = [
        {id: 1, position: {lat: 35.819809470846586, lng: 139.60019028404653}, name: "朝霞第三小学校", minute: "現地から◯◯m"}, 
    ]
    const pin4 = [
        {id: 1, position: {lat: 35.7947034368637, lng: 139.58799051103605}, name: "朝霞第四小学校", minute: "現地から◯◯m"}, 
    ]
    const pin5 = [
        {id: 1, position: {lat: 35.810852702343055, lng: 139.58054359171632}, name: "朝霞第五小学校", minute: "現地から◯◯m"}, 
    ]
    const pin6 = [
        {id: 1, position: {lat: 35.804888018917325, lng: 139.59663619938624}, name: "朝霞第六小学校", minute: "現地から◯◯m"}, 
    ]
    const pin7 = [
        {id: 1, position: {lat: 35.82002656868054, lng: 139.58629962453165}, name: "朝霞第七小学校", minute: "現地から◯◯m"}, 
    ]
    const pin8 = [
        {id: 1, position: {lat: 35.790984457770755, lng: 139.596135668706}, name: "朝霞第八小学校", minute: "現地から◯◯m"}, 
    ]
    const pin9 = [
        {id: 1, position: {lat: 35.809510097182795, lng: 139.61199972637652}, name: "朝霞第九小学校", minute: "現地から◯◯m"}, 
    ]
    const pin10 = [
        {id: 1, position: {lat: 35.80465981068798, lng: 139.5845818110364}, name: "朝霞第十小学校", minute: "現地から◯◯m"}, 
    ]
    const pin11 = [
        {id: 1, position: {lat: 35.79160035859966, lng: 139.590874384046}, name: "朝霞第一中学校", minute: "現地から◯◯m"}, 
    ]
    const pin12 = [
        {id: 1, position: {lat: 35.81540588253505, lng: 139.59772312822145}, name: "朝霞第二中学校", minute: "現地から◯◯m"}, 
    ]
    const pin13 = [
        {id: 1, position: {lat: 35.8077273997853, lng: 139.58586011103637}, name: "朝霞第三中学校", minute: "現地から◯◯m"}, 
    ]
    const pin14 = [
        {id: 1, position: {lat: 35.78964544430602, lng: 139.59540837055084}, name: "朝霞第四中学校", minute: "現地から◯◯m"}, 
    ]
    const pin15 = [
        {id: 1, position: {lat: 35.825180448067144, lng: 139.59707892637658}, name: "朝霞第五中学校", minute: "現地から◯◯m"}, 
    ]
    const pin16 = [
        {id: 1, position: {lat: 35.81348658811049, lng: 139.59127938404643}, name: "浜崎保育園", minute: "現地から◯◯m"}, 
    ]
    const pin17 = [
        {id: 1, position: {lat: 35.80281743641325, lng: 139.60222971288118}, name: "東朝霞保育園", minute: "現地から◯◯m"}, 
    ]
    const pin18 = [
        {id: 1, position: {lat: 35.8081606527451, lng: 139.58763225312302}, name: "溝沼保育園", minute: "現地から◯◯m"}, 
    ]
    const pin19 = [
        {id: 1, position: {lat: 35.80299081531451, lng: 139.5966443384877}, name: "本町保育園", minute: "現地から◯◯m"}, 
    ]
    const pin20 = [
        {id: 1, position: {lat: 35.79939611338684, lng: 139.61054822637615}, name: "根岸台保育園", minute: "現地から◯◯m"}, 
    ]
    const pin21 = [
        {id: 1, position: {lat: 35.81830037397749, lng: 139.5842931687066}, name: "北朝霞保育園", minute: "現地から◯◯m"}, 
    ]
    const pin22 = [
        {id: 1, position: {lat: 35.79676043583955, lng: 139.60026783035084}, name: "栄町保育園", minute: "現地から◯◯m"}, 
    ]

    const tabClick1 = () => {
        setTab1(!tab1)
    }
    const tabClick2 = () => {
        setTab2(!tab2)
    }
    const tabClick3 = () => {
        setTab3(!tab3)
    }
    const tabClick4 = () => {
        setTab4(!tab4)
    }
    const tabClick5 = () => {
        setTab5(!tab5)
    }
    const tabClick6 = () => {
        setTab6(!tab6)
    }
    const tabClick7 = () => {
        setTab7(!tab7)
    }
    const tabClick8 = () => {
        setTab8(!tab8)
    }
    const tabClick9 = () => {
        setTab9(!tab9)
    }
    const tabClick10 = () => {
        setTab10(!tab10)
    }
    const tabClick11 = () => {
        setTab11(!tab11)
    }
    const tabClick12 = () => {
        setTab12(!tab12)
    }
    const tabClick13 = () => {
        setTab13(!tab13)
    }
    const tabClick14 = () => {
        setTab14(!tab14)
    }
    const tabClick15 = () => {
        setTab15(!tab15)
    }
    const tabClick16 = () => {
        setTab16(!tab16)
    }
    const tabClick17 = () => {
        setTab17(!tab17)
    }
    const tabClick18 = () => {
        setTab18(!tab18)
    }
    const tabClick19 = () => {
        setTab19(!tab19)
    }
    const tabClick20 = () => {
        setTab20(!tab20)
    }
    const tabClick21 = () => {
        setTab21(!tab21)
    }
    const tabClick22 = () => {
        setTab22(!tab22)
    }
    const tabClick23 = () => {
        setTab23(!tab23)
    }
    const tabClick24 = () => {
        setTab24(!tab24)
    }
    const tabClick25 = () => {
        setTab25(!tab25)
    }
    const tabClick26 = () => {
        setTab26(!tab26)
    }
    const tabClick27 = () => {
        setTab27(!tab27)
    }
    const tabClick28 = () => {
        setTab28(!tab28)
    }
    const tabClick29 = () => {
        setTab29(!tab29)
    }
    const tabClick30 = () => {
        setTab30(!tab30)
    }
    const tabClick31 = () => {
        setTab31(!tab31)
    }
    const tabClick32 = () => {
        setTab32(!tab32)
    }
    const tabClick33 = () => {
        setTab33(!tab33)
    }
    const tabClick34 = () => {
        setTab34(!tab34)
    }
    const tabClick35 = () => {
        setTab35(!tab35)
    }
    const tabClick36 = () => {
        setTab36(!tab36)
    }
    const tabClick37 = () => {
        setTab37(!tab37)
    }
    const tabClick38 = () => {
        setTab38(!tab38)
    }
    const tabClick39 = () => {
        setTab39(!tab39)
    }
    const tabClick40 = () => {
        setTab40(!tab40)
    }
    const tabClick41 = () => {
        setTab41(!tab41)
    }
    const tabClick42 = () => {
        setTab42(!tab42)
    }

    const clear = () => {
        setTab1(false)
        setTab2(false)
        setTab3(false)
        setTab4(false)
        setTab5(false)
        setTab6(false)
        setTab7(false)
        setTab8(false)
        setTab9(false)
        setTab10(false)
        setTab11(false)
        setTab12(false)
        setTab13(false)
        setTab14(false)
        setTab15(false)
        setTab16(false)
        setTab17(false)
        setTab18(false)
        setTab19(false)
        setTab20(false)
        setTab21(false)
        setTab22(false)
        setTab23(false)
        setTab24(false)
        setTab25(false)
        setTab26(false)
        setTab27(false)
        setTab28(false)
        setTab29(false)
        setTab30(false)
        setTab31(false)
        setTab32(false)
        setTab33(false)
        setTab34(false)
        setTab35(false)
        setTab36(false)
        setTab37(false)
        setTab38(false)
        setTab39(false)
        setTab40(false)
        setTab41(false)
        setTab42(false)
    }

    return(
        <div className="hazard_map_root">
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <h2>HAZARD MAP</h2>
                {/* <button onClick={() => clear()}>Clear</button> */}
            </div>
            <div className="hazard_map">
                {/* <div className="hazard_map_images">
                    <div className="hazard_list">
                        <img src={Hm5} />
                        <div onClick={() => tabClick1()} className={tab1 === true ? "hazard_map_button1" : "hazard_map_button1_none"}></div>
                        <div onClick={() => tabClick2()} className={tab2 === true ? "hazard_map_button2" : "hazard_map_button2_none"}></div>
                        <div onClick={() => tabClick3()} className={tab3 === true ? "hazard_map_button3" : "hazard_map_button3_none"}></div>
                        <div onClick={() => tabClick4()} className={tab4 === true ? "hazard_map_button4" : "hazard_map_button4_none"}></div>
                        <div onClick={() => tabClick5()} className={tab5 === true ? "hazard_map_button5" : "hazard_map_button5_none"}></div>
                        <div onClick={() => tabClick6()} className={tab6 === true ? "hazard_map_button6" : "hazard_map_button6_none"}></div>
                        <div onClick={() => tabClick7()} className={tab7 === true ? "hazard_map_button7" : "hazard_map_button7_none"}></div>
                        <div onClick={() => tabClick8()} className={tab8 === true ? "hazard_map_button8" : "hazard_map_button8_none"}></div>
                        <div onClick={() => tabClick9()} className={tab9 === true ? "hazard_map_button9" : "hazard_map_button9_none"}></div>
                        <div onClick={() => tabClick10()} className={tab10 === true ? "hazard_map_button10" : "hazard_map_button10_none"}></div>
                        <div onClick={() => tabClick11()} className={tab11 === true ? "hazard_map_button11" : "hazard_map_button11_none"}></div>
                        <div onClick={() => tabClick12()} className={tab12 === true ? "hazard_map_button12" : "hazard_map_button12_none"}></div>
                        <div onClick={() => tabClick13()} className={tab13 === true ? "hazard_map_button13" : "hazard_map_button13_none"}></div>
                        <div onClick={() => tabClick14()} className={tab14 === true ? "hazard_map_button14" : "hazard_map_button14_none"}></div>
                        <div onClick={() => tabClick15()} className={tab15 === true ? "hazard_map_button15" : "hazard_map_button15_none"}></div>
                        <div onClick={() => tabClick16()} className={tab16 === true ? "hazard_map_button16" : "hazard_map_button16_none"}></div>
                        <div onClick={() => tabClick17()} className={tab17 === true ? "hazard_map_button17" : "hazard_map_button17_none"}></div>
                        <div onClick={() => tabClick18()} className={tab18 === true ? "hazard_map_button18" : "hazard_map_button18_none"}></div>
                        <div onClick={() => tabClick19()} className={tab19 === true ? "hazard_map_button19" : "hazard_map_button19_none"}></div>
                        <div onClick={() => tabClick20()} className={tab20 === true ? "hazard_map_button20" : "hazard_map_button20_none"}></div>
                        <div onClick={() => tabClick21()} className={tab21 === true ? "hazard_map_button21" : "hazard_map_button21_none"}></div>
                        <div onClick={() => tabClick22()} className={tab22 === true ? "hazard_map_button22" : "hazard_map_button22_none"}></div>
                        <div onClick={() => tabClick23()} className={tab23 === true ? "hazard_map_button23" : "hazard_map_button23_none"}></div>
                        <div onClick={() => tabClick24()} className={tab24 === true ? "hazard_map_button24" : "hazard_map_button24_none"}></div>
                        <div onClick={() => tabClick25()} className={tab25 === true ? "hazard_map_button25" : "hazard_map_button25_none"}></div>
                        <div onClick={() => tabClick26()} className={tab26 === true ? "hazard_map_button26" : "hazard_map_button26_none"}></div>
                        <div onClick={() => tabClick27()} className={tab27 === true ? "hazard_map_button27" : "hazard_map_button27_none"}></div>
                        <div onClick={() => tabClick28()} className={tab28 === true ? "hazard_map_button28" : "hazard_map_button28_none"}></div>
                        <div onClick={() => tabClick29()} className={tab29 === true ? "hazard_map_button29" : "hazard_map_button29_none"}></div>
                        <div onClick={() => tabClick30()} className={tab30 === true ? "hazard_map_button30" : "hazard_map_button30_none"}></div>
                        <div onClick={() => tabClick31()} className={tab31 === true ? "hazard_map_button31" : "hazard_map_button31_none"}></div>
                        <div onClick={() => tabClick32()} className={tab32 === true ? "hazard_map_button32" : "hazard_map_button32_none"}></div>
                        <div onClick={() => tabClick33()} className={tab33 === true ? "hazard_map_button33" : "hazard_map_button33_none"}></div>
                        <div onClick={() => tabClick34()} className={tab34 === true ? "hazard_map_button34" : "hazard_map_button34_none"}></div>
                        <div onClick={() => tabClick35()} className={tab35 === true ? "hazard_map_button35" : "hazard_map_button35_none"}></div>
                        <div onClick={() => tabClick36()} className={tab36 === true ? "hazard_map_button36" : "hazard_map_button36_none"}></div>
                        <div onClick={() => tabClick37()} className={tab37 === true ? "hazard_map_button37" : "hazard_map_button37_none"}></div>
                        <div onClick={() => tabClick38()} className={tab38 === true ? "hazard_map_button38" : "hazard_map_button38_none"}></div>
                        <div onClick={() => tabClick39()} className={tab39 === true ? "hazard_map_button39" : "hazard_map_button39_none"}></div>
                        <div onClick={() => tabClick40()} className={tab40 === true ? "hazard_map_button40" : "hazard_map_button40_none"}></div>
                        <div onClick={() => tabClick41()} className={tab41 === true ? "hazard_map_button41" : "hazard_map_button41_none"}></div>
                        <div onClick={() => tabClick42()} className={tab42 === true ? "hazard_map_button41" : "hazard_map_button42_none"}></div>
                    </div>
                    <h2 onClick={() => navigate('/hazard_map')} style={{width: "100%", textAlign: "center"}}>MAP</h2>
                </div> */}
                <div className="hazard_map_view">
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={18}
                        options={options}
                        onLoad={onLoad}
                    >
                        {pin.map(({id, position, name, minute, url}) => (
                            <Marker
                                key={id}
                                position={position}
                                icon={{
                                    url: url,
                                    anchor: new window.google.maps.Point(30, 46),
                                    scaledSize: new window.google.maps.Size(50, 50),
                                    origin: new window.google.maps.Point(0, 0),
                                }}
                                onClick={() => handleActiveMarker(id)}
                            >
                                {selectedPin === id ? (
                                    <InfoWindow position={position} onCloseClick={() => setSelectedPin(null)}>
                                        <div>
                                            <h3>{name}</h3>
                                        </div>
                                    </InfoWindow>
                                ): null}
                            </Marker>
                        ))}
                        {tab1 === true ? pin1.map(({id, position, name, minute, url}) => (
                            <Marker
                                key={id}
                                position={position}
                                icon={{
                                    // url: url,
                                    anchor: new window.google.maps.Point(30, 46),
                                    scaledSize: new window.google.maps.Size(50, 50),
                                    origin: new window.google.maps.Point(0, 0),
                                }}
                                onClick={() => handleActiveMarker1(id)}
                            >
                                {selectedPin1 === id ? (
                                    <InfoWindow position={position} onCloseClick={() => setSelectedPin1(null)}>
                                        <div>
                                            <h3>{name}</h3>
                                        </div>
                                    </InfoWindow>
                                ): null}
                            </Marker>
                        )): null}
                        {tab2 === true ? pin2.map(({id, position, name, minute, url}) => (
                            <Marker
                                key={id}
                                position={position}
                                icon={{
                                    // url: url,
                                    anchor: new window.google.maps.Point(30, 46),
                                    scaledSize: new window.google.maps.Size(50, 50),
                                    origin: new window.google.maps.Point(0, 0),
                                }}
                                onClick={() => handleActiveMarker2(id)}
                            >
                                {selectedPin2 === id ? (
                                    <InfoWindow position={position} onCloseClick={() => setSelectedPin2(null)}>
                                        <div>
                                            <h3>{name}</h3>
                                        </div>
                                    </InfoWindow>
                                ): null}
                            </Marker>
                        )): null}
                        {tab3 === true ? pin3.map(({id, position, name, minute, url}) => (
                            <Marker
                                key={id}
                                position={position}
                                icon={{
                                    // url: url,
                                    anchor: new window.google.maps.Point(30, 46),
                                    scaledSize: new window.google.maps.Size(50, 50),
                                    origin: new window.google.maps.Point(0, 0),
                                }}
                                onClick={() => handleActiveMarker3(id)}
                            >
                                {selectedPin3 === id ? (
                                    <InfoWindow position={position} onCloseClick={() => setSelectedPin3(null)}>
                                        <div>
                                            <h3>{name}</h3>
                                        </div>
                                    </InfoWindow>
                                ): null}
                            </Marker>
                        )): null}
                        {tab4 === true ? pin4.map(({id, position, name, minute, url}) => (
                            <Marker
                                key={id}
                                position={position}
                                icon={{
                                    // url: url,
                                    anchor: new window.google.maps.Point(30, 46),
                                    scaledSize: new window.google.maps.Size(50, 50),
                                    origin: new window.google.maps.Point(0, 0),
                                }}
                                onClick={() => handleActiveMarker4(id)}
                            >
                                {selectedPin4 === id ? (
                                    <InfoWindow position={position} onCloseClick={() => setSelectedPin4(null)}>
                                        <div>
                                            <h3>{name}</h3>
                                        </div>
                                    </InfoWindow>
                                ): null}
                            </Marker>
                        )): null}
                        {tab5 === true ? pin5.map(({id, position, name, minute, url}) => (
                            <Marker
                                key={id}
                                position={position}
                                icon={{
                                    // url: url,
                                    anchor: new window.google.maps.Point(30, 46),
                                    scaledSize: new window.google.maps.Size(50, 50),
                                    origin: new window.google.maps.Point(0, 0),
                                }}
                                onClick={() => handleActiveMarker5(id)}
                            >
                                {selectedPin5 === id ? (
                                    <InfoWindow position={position} onCloseClick={() => setSelectedPin5(null)}>
                                        <div>
                                            <h3>{name}</h3>
                                        </div>
                                    </InfoWindow>
                                ): null}
                            </Marker>
                        )): null}
                        {tab6 === true ? pin6.map(({id, position, name, minute, url}) => (
                            <Marker
                                key={id}
                                position={position}
                                icon={{
                                    // url: url,
                                    anchor: new window.google.maps.Point(30, 46),
                                    scaledSize: new window.google.maps.Size(50, 50),
                                    origin: new window.google.maps.Point(0, 0),
                                }}
                                onClick={() => handleActiveMarker6(id)}
                            >
                                {selectedPin6 === id ? (
                                    <InfoWindow position={position} onCloseClick={() => setSelectedPin6(null)}>
                                        <div>
                                            <h3>{name}</h3>
                                        </div>
                                    </InfoWindow>
                                ): null}
                            </Marker>
                        )): null}
                        {tab7 === true ? pin7.map(({id, position, name, minute, url}) => (
                            <Marker
                                key={id}
                                position={position}
                                icon={{
                                    // url: url,
                                    anchor: new window.google.maps.Point(30, 46),
                                    scaledSize: new window.google.maps.Size(50, 50),
                                    origin: new window.google.maps.Point(0, 0),
                                }}
                                onClick={() => handleActiveMarker7(id)}
                            >
                                {selectedPin7 === id ? (
                                    <InfoWindow position={position} onCloseClick={() => setSelectedPin7(null)}>
                                        <div>
                                            <h3>{name}</h3>
                                        </div>
                                    </InfoWindow>
                                ): null}
                            </Marker>
                        )): null}
                        {tab8 === true ? pin8.map(({id, position, name, minute, url}) => (
                            <Marker
                                key={id}
                                position={position}
                                icon={{
                                    // url: url,
                                    anchor: new window.google.maps.Point(30, 46),
                                    scaledSize: new window.google.maps.Size(50, 50),
                                    origin: new window.google.maps.Point(0, 0),
                                }}
                                onClick={() => handleActiveMarker8(id)}
                            >
                                {selectedPin8 === id ? (
                                    <InfoWindow position={position} onCloseClick={() => setSelectedPin8(null)}>
                                        <div>
                                            <h3>{name}</h3>
                                        </div>
                                    </InfoWindow>
                                ): null}
                            </Marker>
                        )): null}
                        {tab9 === true ? pin9.map(({id, position, name, minute, url}) => (
                            <Marker
                                key={id}
                                position={position}
                                icon={{
                                    // url: url,
                                    anchor: new window.google.maps.Point(30, 46),
                                    scaledSize: new window.google.maps.Size(50, 50),
                                    origin: new window.google.maps.Point(0, 0),
                                }}
                                onClick={() => handleActiveMarker9(id)}
                            >
                                {selectedPin9 === id ? (
                                    <InfoWindow position={position} onCloseClick={() => setSelectedPin9(null)}>
                                        <div>
                                            <h3>{name}</h3>
                                        </div>
                                    </InfoWindow>
                                ): null}
                            </Marker>
                        )): null}
                        {tab10 === true ? pin10.map(({id, position, name, minute, url}) => (
                            <Marker
                                key={id}
                                position={position}
                                icon={{
                                    // url: url,
                                    anchor: new window.google.maps.Point(30, 46),
                                    scaledSize: new window.google.maps.Size(50, 50),
                                    origin: new window.google.maps.Point(0, 0),
                                }}
                                onClick={() => handleActiveMarker10(id)}
                            >
                                {selectedPin10 === id ? (
                                    <InfoWindow position={position} onCloseClick={() => setSelectedPin10(null)}>
                                        <div>
                                            <h3>{name}</h3>
                                        </div>
                                    </InfoWindow>
                                ): null}
                            </Marker>
                        )): null}
                        {tab11 === true ? pin11.map(({id, position, name, minute, url}) => (
                            <Marker
                                key={id}
                                position={position}
                                icon={{
                                    // url: url,
                                    anchor: new window.google.maps.Point(30, 46),
                                    scaledSize: new window.google.maps.Size(50, 50),
                                    origin: new window.google.maps.Point(0, 0),
                                }}
                                onClick={() => handleActiveMarker11(id)}
                            >
                                {selectedPin11 === id ? (
                                    <InfoWindow position={position} onCloseClick={() => setSelectedPin11(null)}>
                                        <div>
                                            <h3>{name}</h3>
                                        </div>
                                    </InfoWindow>
                                ): null}
                            </Marker>
                        )): null}
                        {tab12 === true ? pin12.map(({id, position, name, minute, url}) => (
                            <Marker
                                key={id}
                                position={position}
                                icon={{
                                    // url: url,
                                    anchor: new window.google.maps.Point(30, 46),
                                    scaledSize: new window.google.maps.Size(50, 50),
                                    origin: new window.google.maps.Point(0, 0),
                                }}
                                onClick={() => handleActiveMarker12(id)}
                            >
                                {selectedPin12 === id ? (
                                    <InfoWindow position={position} onCloseClick={() => setSelectedPin12(null)}>
                                        <div>
                                            <h3>{name}</h3>
                                        </div>
                                    </InfoWindow>
                                ): null}
                            </Marker>
                        )): null}
                        {tab13 === true ? pin13.map(({id, position, name, minute, url}) => (
                            <Marker
                                key={id}
                                position={position}
                                icon={{
                                    // url: url,
                                    anchor: new window.google.maps.Point(30, 46),
                                    scaledSize: new window.google.maps.Size(50, 50),
                                    origin: new window.google.maps.Point(0, 0),
                                }}
                                onClick={() => handleActiveMarker13(id)}
                            >
                                {selectedPin13 === id ? (
                                    <InfoWindow position={position} onCloseClick={() => setSelectedPin13(null)}>
                                        <div>
                                            <h3>{name}</h3>
                                        </div>
                                    </InfoWindow>
                                ): null}
                            </Marker>
                        )): null}
                        {tab14 === true ? pin14.map(({id, position, name, minute, url}) => (
                            <Marker
                                key={id}
                                position={position}
                                icon={{
                                    // url: url,
                                    anchor: new window.google.maps.Point(30, 46),
                                    scaledSize: new window.google.maps.Size(50, 50),
                                    origin: new window.google.maps.Point(0, 0),
                                }}
                                onClick={() => handleActiveMarker14(id)}
                            >
                                {selectedPin14 === id ? (
                                    <InfoWindow position={position} onCloseClick={() => setSelectedPin14(null)}>
                                        <div>
                                            <h3>{name}</h3>
                                        </div>
                                    </InfoWindow>
                                ): null}
                            </Marker>
                        )): null}
                        {tab15 === true ? pin15.map(({id, position, name, minute, url}) => (
                            <Marker
                                key={id}
                                position={position}
                                icon={{
                                    // url: url,
                                    anchor: new window.google.maps.Point(30, 46),
                                    scaledSize: new window.google.maps.Size(50, 50),
                                    origin: new window.google.maps.Point(0, 0),
                                }}
                                onClick={() => handleActiveMarker15(id)}
                            >
                                {selectedPin15 === id ? (
                                    <InfoWindow position={position} onCloseClick={() => setSelectedPin15(null)}>
                                        <div>
                                            <h3>{name}</h3>
                                        </div>
                                    </InfoWindow>
                                ): null}
                            </Marker>
                        )): null}
                        {tab16 === true ? pin16.map(({id, position, name, minute, url}) => (
                            <Marker
                                key={id}
                                position={position}
                                icon={{
                                    // url: url,
                                    anchor: new window.google.maps.Point(30, 46),
                                    scaledSize: new window.google.maps.Size(50, 50),
                                    origin: new window.google.maps.Point(0, 0),
                                }}
                                onClick={() => handleActiveMarker16(id)}
                            >
                                {selectedPin16 === id ? (
                                    <InfoWindow position={position} onCloseClick={() => setSelectedPin16(null)}>
                                        <div>
                                            <h3>{name}</h3>
                                        </div>
                                    </InfoWindow>
                                ): null}
                            </Marker>
                        )): null}
                        {tab17 === true ? pin17.map(({id, position, name, minute, url}) => (
                            <Marker
                                key={id}
                                position={position}
                                icon={{
                                    // url: url,
                                    anchor: new window.google.maps.Point(30, 46),
                                    scaledSize: new window.google.maps.Size(50, 50),
                                    origin: new window.google.maps.Point(0, 0),
                                }}
                                onClick={() => handleActiveMarker17(id)}
                            >
                                {selectedPin17 === id ? (
                                    <InfoWindow position={position} onCloseClick={() => setSelectedPin17(null)}>
                                        <div>
                                            <h3>{name}</h3>
                                        </div>
                                    </InfoWindow>
                                ): null}
                            </Marker>
                        )): null}
                        {tab18 === true ? pin18.map(({id, position, name, minute, url}) => (
                            <Marker
                                key={id}
                                position={position}
                                icon={{
                                    // url: url,
                                    anchor: new window.google.maps.Point(30, 46),
                                    scaledSize: new window.google.maps.Size(50, 50),
                                    origin: new window.google.maps.Point(0, 0),
                                }}
                                onClick={() => handleActiveMarker18(id)}
                            >
                                {selectedPin18 === id ? (
                                    <InfoWindow position={position} onCloseClick={() => setSelectedPin18(null)}>
                                        <div>
                                            <h3>{name}</h3>
                                        </div>
                                    </InfoWindow>
                                ): null}
                            </Marker>
                        )): null}
                        {tab19 === true ? pin19.map(({id, position, name, minute, url}) => (
                            <Marker
                                key={id}
                                position={position}
                                icon={{
                                    // url: url,
                                    anchor: new window.google.maps.Point(30, 46),
                                    scaledSize: new window.google.maps.Size(50, 50),
                                    origin: new window.google.maps.Point(0, 0),
                                }}
                                onClick={() => handleActiveMarker19(id)}
                            >
                                {selectedPin19 === id ? (
                                    <InfoWindow position={position} onCloseClick={() => setSelectedPin19(null)}>
                                        <div>
                                            <h3>{name}</h3>
                                        </div>
                                    </InfoWindow>
                                ): null}
                            </Marker>
                        )): null}
                        {tab20 === true ? pin20.map(({id, position, name, minute, url}) => (
                            <Marker
                                key={id}
                                position={position}
                                icon={{
                                    // url: url,
                                    anchor: new window.google.maps.Point(30, 46),
                                    scaledSize: new window.google.maps.Size(50, 50),
                                    origin: new window.google.maps.Point(0, 0),
                                }}
                                onClick={() => handleActiveMarker20(id)}
                            >
                                {selectedPin20 === id ? (
                                    <InfoWindow position={position} onCloseClick={() => setSelectedPin20(null)}>
                                        <div>
                                            <h3>{name}</h3>
                                        </div>
                                    </InfoWindow>
                                ): null}
                            </Marker>
                        )): null}
                        {tab21 === true ? pin21.map(({id, position, name, minute, url}) => (
                            <Marker
                                key={id}
                                position={position}
                                icon={{
                                    // url: url,
                                    anchor: new window.google.maps.Point(30, 46),
                                    scaledSize: new window.google.maps.Size(50, 50),
                                    origin: new window.google.maps.Point(0, 0),
                                }}
                                onClick={() => handleActiveMarker21(id)}
                            >
                                {selectedPin21 === id ? (
                                    <InfoWindow position={position} onCloseClick={() => setSelectedPin21(null)}>
                                        <div>
                                            <h3>{name}</h3>
                                        </div>
                                    </InfoWindow>
                                ): null}
                            </Marker>
                        )): null}
                        {tab22 === true ? pin22.map(({id, position, name, minute, url}) => (
                            <Marker
                                key={id}
                                position={position}
                                icon={{
                                    // url: url,
                                    anchor: new window.google.maps.Point(30, 46),
                                    scaledSize: new window.google.maps.Size(50, 50),
                                    origin: new window.google.maps.Point(0, 0),
                                }}
                                onClick={() => handleActiveMarker22(id)}
                            >
                                {selectedPin22 === id ? (
                                    <InfoWindow position={position} onCloseClick={() => setSelectedPin22(null)}>
                                        <div>
                                            <h3>{name}</h3>
                                        </div>
                                    </InfoWindow>
                                ): null}
                            </Marker>
                        )): null}
                    </GoogleMap>
                </div>
                <div>
                    <div className="map_button_root">
                        <div className="map_button">
                            <div className="map_button_item ed active">
                                <img src={EduB} />
                                <div className="map_border ed" />
                                <p>EDUCATION</p>
                            </div>
                            <div className="map_button_item me active">
                                <img src={MedB} />
                                <div className="map_border me" />
                                <p>MEDICAL</p>
                            </div>
                        </div>
                        <div className="map_button">
                            <div className="map_button_item sh active">
                                <img src={ShopB} />
                                <div className="map_border sh" />
                                <p>SHOPPING</p>
                            </div>
                            <div className="map_button_item ra active">
                                <img src={RailB} />
                                <div className="map_border ra"  />
                                <p>RESTAURANT</p>
                            </div>
                        </div>
                        <div className="map_button">
                            <div className="map_button_item bs active">
                                <img src={BusB} />
                                <div className="map_border bs"  />
                                <p>RAIL WAY</p>
                            </div>
                            <div className="map_button_item re active">
                                <img src={RestB} />
                                <div className="map_border re" />
                                <p>BUS STOP</p>
                            </div>
                        </div>
                        <div className="map_button">
                            <div className="map_button_item ba active">
                                <img src={BankB} />
                                <div className="map_border ba"  />
                                <p>BANK</p>
                            </div>
                            <div className="map_button_item pu active">
                                <img src={PubB} />
                                <div className="map_border pu"  />
                                <p>PUBLIC</p>
                            </div>
                        </div>
                    </div>
                    <div className="space_small_module" />
                    <MapMenuButtonList id={id} />
                </div>
            </div>
        </div>
    )
}

export default HazardMap2;