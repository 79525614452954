import React from "react";
import { useNavigate } from "react-router-dom";

const ColorSelectNav = (props) => {
    const navigate = useNavigate();
    const id = props.id
    return(
        <div className="slect_nav">
            <button onClick={() => navigate('/living_dining')} className={id === "living_dining" ? "slect_nav_button active" : "slect_nav_button"}>LIVING DINING</button>
            <button onClick={() => navigate('/kitchen')} className={id === "kitchen" ? "slect_nav_button active" : "slect_nav_button"}>KITCHEN</button>
            <button onClick={() => navigate('/powder_room')} className={id === "powder_room" ? "slect_nav_button active" : "slect_nav_button"}>POWDER ROOM</button>
            <button onClick={() => navigate('/bath_room')} className={id === "bath_room" ? "slect_nav_button active" : "slect_nav_button"}>BATHROOM</button>
            <button onClick={() => navigate('/lavatory')} className={id === "lavatory" ? "slect_nav_button active" : "slect_nav_button"}>LAVATORY</button>
        </div>
    )
}
export default ColorSelectNav;