import React from "react";
import ATypeEntrance from "../../img/Atype/A1_entrance.jpg";
import AtypeLiving from "../../img/Atype/A1_living.jpg";
import AtypeBed1 from "../../img/Atype/A1_bed1.jpg";
import AtypeBed2 from "../../img/Atype/A1_bed2.jpg";
import AtypeBed3 from "../../img/Atype/A1_bed3.jpg";
import AtypeBath from "../../img/Atype/A1_bath.jpg";
import AtypeLav from "../../img/Atype/A1_lavatory.jpg";
import AtypePowder from "../../img/Atype/A1_poweder.jpg";
import AtypeWIC from "../../img/Atype/A1_WIC.jpg";
import AtypeSEntrance from "../../img/Atype/A1_s_entrance.jpg";
import AtypeSLiving from "../../img/Atype/A1_s_living.jpg";
import AtypeSBed1 from "../../img/Atype/A1_s_bed1.jpg";
import AtypeSBed2 from "../../img/Atype/A1_s_bed2.jpg";
import AtypeSBed3 from "../../img/Atype/A1_s_bed3.jpg";
import AtypeSBath from "../../img/Atype/A1_s_bath.jpg";
import AtypeSLav from "../../img/Atype/A1_s_lavatory.jpg";
import AtypeSPowder from "../../img/Atype/A1_s_powder.jpg";
import AtypeSWIC from "../../img/Atype/A1_s_WIC.jpg";
import AtypeIEntrance from "../../img/Atype/A1_i_entrance.jpg";
import AtypeILiving from "../../img/Atype/A1_i_living.jpg";
import AtypeIBed1 from "../../img/Atype/A1_i_bed1.jpg";
import AtypeIBed2 from "../../img/Atype/A1_i_bed2.jpg";
import AtypeIBed3 from "../../img/Atype/A1_i_bed3.jpg";
import AtypeIBath from "../../img/Atype/A1_i_bath.jpg";
import AtypeILav from "../../img/Atype/A1_i_lavatory.jpg";
import AtypeIPowder from "../../img/Atype/A1_i_powder.jpg";
import AtypeIWIC from "../../img/Atype/A1_i_WIC.jpg";

const Atype = (props) => {
    const roomToggle = props.roomToggle;
    const versionToggle = props.versionToggle;
    return(
        <div className="room_type_toogle_img_frame">
            <div className={roomToggle === 1 ? "room_type_toogle_img_item" : "room_type_toogle_img_item none"}>
                <div className={versionToggle === 1 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={ATypeEntrance} />
                </div>
                <div className={versionToggle === 2 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={AtypeSEntrance} />
                </div>
                <div className={versionToggle === 3 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={AtypeIEntrance} />
                </div>
            </div>
            <div className={roomToggle === 2 ? "room_type_toogle_img_item" : "room_type_toogle_img_item none"}>
                <div className={versionToggle === 1 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={AtypeLiving} />
                </div>
                <div className={versionToggle === 2 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={AtypeSLiving} />
                </div>
                <div className={versionToggle === 3 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={AtypeILiving} />
                </div>
            </div>
            <div className={roomToggle === 3 ? "room_type_toogle_img_item" : "room_type_toogle_img_item none"}>
                <div className={versionToggle === 1 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={AtypeBed1} />
                </div>
                <div className={versionToggle === 2 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={AtypeSBed1} />
                </div>
                <div className={versionToggle === 3 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={AtypeIBed1} />
                </div>
            </div>
            <div className={roomToggle === 4 ? "room_type_toogle_img_item" : "room_type_toogle_img_item none"}>
                <div className={versionToggle === 1 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={AtypeBed2} />
                </div>
                <div className={versionToggle === 2 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={AtypeSBed2} />
                </div>
                <div className={versionToggle === 3 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={AtypeIBed2} />
                </div>
            </div>
            <div className={roomToggle === 5 ? "room_type_toogle_img_item" : "room_type_toogle_img_item none"}>
                <div className={versionToggle === 1 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={AtypeBed3} />
                </div>
                <div className={versionToggle === 2 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={AtypeSBed3} />
                </div>
                <div className={versionToggle === 3 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={AtypeIBed3} />
                </div>
            </div>
            <div className={roomToggle === 6 ? "room_type_toogle_img_item" : "room_type_toogle_img_item none"}>
                <div className={versionToggle === 1 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={AtypeBath} />
                </div>
                <div className={versionToggle === 2 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={AtypeSBath} />
                </div>
                <div className={versionToggle === 3 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={AtypeIBath} />
                </div>
            </div>
            <div className={roomToggle === 7 ? "room_type_toogle_img_item" : "room_type_toogle_img_item none"}>
                <div className={versionToggle === 1 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={AtypeLav} />
                </div>
                <div className={versionToggle === 2 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={AtypeSLav} />
                </div>
                <div className={versionToggle === 3 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={AtypeILav} />
                </div>
            </div>
            <div className={roomToggle === 8 ? "room_type_toogle_img_item" : "room_type_toogle_img_item none"}>
                <div className={versionToggle === 1 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={AtypePowder} />
                </div>
                <div className={versionToggle === 2 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={AtypeSPowder} />
                </div>
                <div className={versionToggle === 3 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={AtypeIPowder} />
                </div>
            </div>
            <div className={roomToggle === 9 ? "room_type_toogle_img_item" : "room_type_toogle_img_item none"}>
                <div className={versionToggle === 1 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={AtypeWIC} />
                </div>
                <div className={versionToggle === 2 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={AtypeSWIC} />
                </div>
                <div className={versionToggle === 3 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={AtypeIWIC} />
                </div>
            </div>
        </div>
    )
}

export default Atype;