import React, { useState } from "react";
import Title from '../img/roomplan_sozai2/roomplan_title.png'
import Ezumen from '../img/room_sozai/E_zu.png'
import Emm from '../img/roomplan_sozai2/emm.png'
import Bath from "../img/Esozai/e_bath.jpg"
import Bed from "../img/Esozai/e_bed.jpg"
import Hoby from "../img/Esozai/e_hoby.jpg"
import Japan from "../img/Esozai/e_japan.jpg"
import Living from "../img/Esozai/e_living.jpg"
import Shoose from "../img/Esozai/e_shoose.jpg"
import Wash from "../img/Esozai/e_wash.jpg"
import Wc from "../img/Esozai/e_wc.jpg"
import TypeButton from "../component/TypeButton";
import MenuList from "../component/MenuList";
import FloorPlanMenu from "../component/FloorPlanMenu";

const RoomPlanE = () => {
  const path = window.location.href
  const id = path.split('/#/')[1]
  const [imgToggle, setImgToggle] = useState(1);
  const [planToggle, setPlanToggle] = useState(0);
  const imgToggleTab = (index) => {
    setImgToggle(index);
  };
    return(
    <div className="root">
      <div className="main_container">
        <div className='room_plan'>
          <h2>ROOM PLAN</h2>
          <div>
            <div className='room_type_view'>
              <img src={Ezumen} />
              <div onClick={() => imgToggleTab(8)} className={imgToggle === 8 ? "active_point1_e" : "point1_e"} />
              <div onClick={() => imgToggleTab(2)} className={imgToggle === 2 ? "active_point2_e" : "point2_e"} />
              <div onClick={() => imgToggleTab(3)} className={imgToggle === 3 ? "active_point3_e" : "point3_e"} />
              <div onClick={() => imgToggleTab(4)} className={imgToggle === 4 ? "active_point4_e" : "point4_e"} />
              <div onClick={() => imgToggleTab(5)} className={imgToggle === 5 ? "active_point5_e" : "point5_e"} />
              <div onClick={() => imgToggleTab(6)} className={imgToggle === 6 ? "active_point6_e" : "point6_e"} />
              <div onClick={() => imgToggleTab(7)} className={imgToggle === 7 ? "active_point7_e" : "point7_e"} />
              <div onClick={() => imgToggleTab(1)} className={imgToggle === 1 ? "active_point8_e" : "point8_e"} />
            </div>
            <div className="space_small_module" />
            <img className="room_type_tab" src={Emm} />
          </div>
        </div>
        <div className='room_view'>
          <div className="room_menu">
            <MenuList id={id} />
            <div className="space_small_module" />
            <TypeButton id={id} />
            <div className="space_small_module" />
            <FloorPlanMenu planToggle={planToggle} setPlanToggle={setPlanToggle} />
          </div>
          <div className="space_small_module" />
          <div className="type_room">
            <div className={imgToggle === 8 ? "active_content" : "contet"}>
              <img src={Hoby} />
              <p>寝室</p>
            </div>
            <div className={imgToggle === 2 ? "active_content" : "contet"}>
              <img src={Shoose} />
              <p>靴箱</p>
            </div>
            <div className={imgToggle === 3 ? "active_content" : "contet"}>
              <img src={Bath} />
              <p>浴室</p>
            </div>
            <div className={imgToggle === 4 ? "active_content" : "contet"}>
              <img src={Wc} />
              <p>トイレ</p>
            </div>
            <div className={imgToggle === 5 ? "active_content" : "contet"}>
              <img src={Wash} />
              <p>洗面所</p>
            </div>
            <div className={imgToggle === 6 ? "active_content" : "contet"}>
              <img src={Bed} />
              <p>寝室</p>
            </div>
            <div className={imgToggle === 7 ? "active_content" : "contet"}>
              <img src={Japan} />
              <p>和室</p>
            </div>
            <div className={imgToggle === 1 ? "active_content" : "contet"}>
              <img src={Living} />
              <p>リビング</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
}

export default RoomPlanE;