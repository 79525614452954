import React, { useState } from "react";
import Title from '../img/roomplan_sozai2/roomplan_title.png'
import Dzumen from '../img/room_sozai/D_zu.png'
import Dmm from '../img/roomplan_sozai2/dmm.png'
import Bed from "../img/Dsozai/d_bed.jpg"
import Bath from "../img/Dsozai/d_bath.jpg"
import Japan from "../img/Dsozai/d_japan.jpg"
import Kids from "../img/Dsozai/d_kids.jpg"
import Living from "../img/Dsozai/d_living.jpg"
import Room from "../img/Dsozai/d_room.jpg"
import Shoose from "../img/Dsozai/d_shoose.jpg"
import Wc from "../img/Dsozai/d_wc.jpg"
import Wash from "../img/Dsozai/d_wash.jpg"
import TypeButton from "../component/TypeButton";
import MenuList from "../component/MenuList";
import FloorPlanMenu from "../component/FloorPlanMenu";

const RoomPlanD = () => {
  const path = window.location.href
  const id = path.split('/#/')[1]
  const [imgToggle, setImgToggle] = useState(1);
  const [planToggle, setPlanToggle] = useState(0);
  const imgToggleTab = (index) => {
    setImgToggle(index);
  };
    return(
    <div className="root">
      <div className="main_container">
        <div className='room_plan'>
            <h2>ROOM PLAN</h2>
            <div>
              <div className='room_type_view'>
                <img src={Dzumen} />
                <div onClick={() => imgToggleTab(8)} className={imgToggle === 8 ? "active_point1_d" : "point1_d"} />
                <div onClick={() => imgToggleTab(2)} className={imgToggle === 2 ? "active_point2_d" : "point2_d"} />
                <div onClick={() => imgToggleTab(3)} className={imgToggle === 3 ? "active_point3_d" : "point3_d"} />
                <div onClick={() => imgToggleTab(4)} className={imgToggle === 4 ? "active_point4_d" : "point4_d"} />
                <div onClick={() => imgToggleTab(5)} className={imgToggle === 5 ? "active_point5_d" : "point5_d"} />
                <div onClick={() => imgToggleTab(6)} className={imgToggle === 6 ? "active_point6_d" : "point6_d"} />
                <div onClick={() => imgToggleTab(7)} className={imgToggle === 7 ? "active_point7_d" : "point7_d"} />
                <div onClick={() => imgToggleTab(1)} className={imgToggle === 1 ? "active_point8_d" : "point8_d"} />
                <div onClick={() => imgToggleTab(9)} className={imgToggle === 9 ? "active_point9_d" : "point9_d"} />
              </div>
              <div className="space_small_module" />
              <img className="room_type_tab" src={Dmm} />
            </div>
        </div>
        <div className='room_view'>
          <div className="room_menu">
            <MenuList id={id} />
            <div className="space_small_module" />
            <TypeButton id={id} />
            <div className="space_small_module" />
            <FloorPlanMenu planToggle={planToggle} setPlanToggle={setPlanToggle} />
          </div>
          <div className="space_small_module" />
          <div className="type_room">
            <div className={imgToggle === 8 ? "active_content" : "contet"}>
              <img src={Kids} />
              <p>子供部屋</p>
            </div>
            <div className={imgToggle === 2 ? "active_content" : "contet"}>
              <img src={Shoose} />
              <p>靴箱</p>
            </div>
            <div className={imgToggle === 3 ? "active_content" : "contet"}>
              <img src={Bed} />
              <p>寝室</p>
            </div>
            <div className={imgToggle === 4 ? "active_content" : "contet"}>
              <img src={Wc} />
              <p>トイレ</p>
            </div>
            <div className={imgToggle === 5 ? "active_content" : "contet"}>
              <img src={Wash} />
              <p>洗面所</p>
            </div>
            <div className={imgToggle === 6 ? "active_content" : "contet"}>
              <img src={Bath} />
              <p>浴室</p>
            </div>
            <div className={imgToggle === 7 ? "active_content" : "contet"}>
              <img src={Japan} />
              <p>和室</p>
            </div>
            <div className={imgToggle === 1 ? "active_content" : "contet"}>
              <img src={Living} />
              <p>リビング</p>
            </div>
            <div className={imgToggle === 9 ? "active_content" : "contet"}>
              <img src={Room} />
              <p>寝室</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
}

export default RoomPlanD;