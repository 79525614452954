import React from 'react';
import { BrowserRouter, Route, Routes, HashRouter } from 'react-router-dom';
import './App.css';
import './Reset.css';
import './css/PriceList.css';
import './css/Simulation.css';
import './css/RoomPlanX.css';
import './css/roomplanA.css';
import './css/roomplanB.css';
import './css/roomplanC.css';
import './css/roomplanD.css';
import './css/roomplanE.css';
import './css/RoomPlan.css';
import './css/Map.css';
import './css/Dotto.css';
import './css/View.css';
import './css/Monthly.css';
import './css/AdminMonthly.css';
import './css/HazardMap.css';
import './css/Sequlity.css';
import './css/ColorSelect.css';
import './css/Calender.css';
import './css/Toggle.css';
import PriceList from './template/PriceList';
import Simulation from './template/Simulation';
import RoomPlan from './template/RoomPlan';
import RoomPlanA from './template/RoomPlanA';
import RoomPlanB from './template/RoomPlanB';
import RoomPlanC from './template/RoomPlanC';
import RoomPlanD from './template/RoomPlanD';
import RoomPlanE from './template/RoomPlanE';
import CGgarally from './template/CGgarally';
import LandScape from './template/Landscape';
import MapPage from './template/Map';
import View from './template/View';
import Monthly from './template/Monthly';
import Carender from './template/Carender';
import HazardMap from './template/HazardMap';
import HazardMap2 from './template/HazardMap2';
import SitePlan from './template/SitePlan';
import ElevationPlan from './template/ElevationPlan';
import AerialShoot from './template/AerialShoot';
import LifeInfo from './template/LifeInfo';
import Sequlity from './template/Sequlity';
import AfterSales from './template/AfterSales';
import LivingDining from './template/ColorSelect/LivingDining';
import Kitchen from './template/ColorSelect/Kitchen';
import PowderRoom from './template/ColorSelect/PowderRoom';
import BathRoom from './template/ColorSelect/BathRoom';
import Lavatory from './template/ColorSelect/Lavatory';
import ToggleRoom from './template/ToggleRoom';


function App() {
  return (
    <HashRouter>
      <Routes>
        {/* <Route path={'/'} element={<Vr />} /> */}
        <Route path={'/'} element={<PriceList />} />
        <Route path={'/carender'} element={<Carender />} />
        <Route path={'/monthly'} element={<Monthly />} />
        <Route path={'/view'} element={<View />} />
        <Route path={'/land_scape'} element={<LandScape />} />
        <Route path={'/siteplan'} element={<SitePlan />} />
        <Route path={'/elevationplan'} element={<ElevationPlan />} />
        <Route path={'/toggle_room'} element={<ToggleRoom />} />
        <Route path={'/roomplan'} element={<RoomPlan />} />
        <Route path={'/roomplan_a'} element={<RoomPlanA />} />
        <Route path={'/roomplan_b'} element={<RoomPlanB />} />
        <Route path={'/roomplan_c'} element={<RoomPlanC />} />
        <Route path={'/roomplan_d'} element={<RoomPlanD />} />
        <Route path={'/roomplan_e'} element={<RoomPlanE />} />
        <Route path={'/simulate'} element={<Simulation />} />
        <Route path={'/cg_garally'} element={<CGgarally />} />
        <Route path={'/map'} element={<MapPage />} />
        <Route path={'/aerial_shoot'} element={<AerialShoot />} />
        <Route path={'/life_infomation'} element={<LifeInfo />} />
        <Route path={'/hazard_map'} element={<HazardMap />} />
        <Route path={'/hazard_map2'} element={<HazardMap2 />} />
        <Route path={'/sequlity'} element={<Sequlity />} />
        <Route path={'/after_sales'} element={<AfterSales />} />
        <Route path={'/living_dining'} element={<LivingDining />} />
        <Route path={'/kitchen'} element={<Kitchen />} />
        <Route path={'/powder_room'} element={<PowderRoom />} />
        <Route path={'/bath_room'} element={<BathRoom />} />
        <Route path={'/lavatory'} element={<Lavatory />} />
      </Routes>
    </HashRouter>
  );
}

export default App;
