import React, {useState} from "react";
import Room from "../img/sitsunai.png";
import S1 from "../img/south/S2.jpg"
import S2 from "../img/south/S3.jpg"
import S3 from "../img/south/S4.jpg"
import S4 from "../img/south/S5.jpg"
import S5 from "../img/south/S6.jpg"
import S6 from "../img/south/S7.jpg"
import S7 from "../img/south/S8.jpg"
import S8 from "../img/south/S9.jpg"
import S9 from "../img/south/S10.jpg"
import S10 from "../img/south/S11.jpg"
import S11 from "../img/south/S12.jpg"
import S12 from "../img/south/S13.jpg"
import S13 from "../img/south/S14.jpg"
import W1 from "../img/west/W2.jpg"
import W2 from "../img/west/W3.jpg"
import W3 from "../img/west/W4.jpg"
import W4 from "../img/west/W5.jpg"
import W5 from "../img/west/W6.jpg"
import W6 from "../img/west/W7.jpg"
import W7 from "../img/west/W8.jpg"
import W8 from "../img/west/W9.jpg"
import W9 from "../img/west/W10.jpg"
import W10 from "../img/west/W11.jpg"
import W11 from "../img/west/W12.jpg"
import W12 from "../img/west/W13.jpg"
import W13 from "../img/west/W14.jpg"
import MenuList from "../component/MenuList";

const View = () => {
    const path = window.location.href
    const id = path.split('/#/')[1]
    const [toggle, setToggle] = useState(8)
    const [directionToggle, setDirectionToggle] = useState(1);
    const directToggleTab = (index) => {
        setDirectionToggle(index)
    }
    const viewToggleTab = (index) => {
        setToggle(index)
    }

    return(
        <div className="view_root">
            <div className="main_container">
                <div className="view_wrapper">
                    <h2>FLOOR VIEW</h2>
                </div>
                <div className="side_menu">
                    <MenuList id={id} />
                    <div className="space_midle_module" />
                    <div className="view_togle">
                        <div onClick={() => directToggleTab(1)} className={directionToggle === 1 ? "toggle_button active" : "toggle_button"}>
                            <button>South</button>
                        </div>
                        <div onClick={() => directToggleTab(2)} className={directionToggle === 2 ? "toggle_button active" : "toggle_button"}>
                            <button>West</button>
                        </div>
                    </div>
                    <div className="elevate_button_colum">
                        <div onClick={() => viewToggleTab(13)} className={toggle === 13 ? "elevate_button active" : "elevate_button"}>14F</div>
                        <div onClick={() => viewToggleTab(12)} className={toggle === 12 ? "elevate_button active" : "elevate_button"}>13F</div>
                        <div onClick={() => viewToggleTab(11)} className={toggle === 11 ? "elevate_button active" : "elevate_button"}>12F</div>
                        <div onClick={() => viewToggleTab(10)} className={toggle === 10 ? "elevate_button active" : "elevate_button"}>11F</div>
                        <div onClick={() => viewToggleTab(9)} className={toggle === 9 ? "elevate_button active" : "elevate_button"}>10F</div>
                        <div onClick={() => viewToggleTab(8)} className={toggle === 8 ? "elevate_button active" : "elevate_button"}>9F</div>
                        <div onClick={() => viewToggleTab(7)} className={toggle === 7 ? "elevate_button active" : "elevate_button"}>8F</div>
                        <div onClick={() => viewToggleTab(6)} className={toggle === 6 ? "elevate_button active" : "elevate_button"}>7F</div>
                        <div onClick={() => viewToggleTab(5)} className={toggle === 5 ? "elevate_button active" : "elevate_button"}>6F</div>
                        <div onClick={() => viewToggleTab(4)} className={toggle === 4 ? "elevate_button active" : "elevate_button"}>5F</div>
                        <div onClick={() => viewToggleTab(3)} className={toggle === 3 ? "elevate_button active" : "elevate_button"}>4F</div>
                        <div onClick={() => viewToggleTab(2)} className={toggle === 2 ? "elevate_button active" : "elevate_button"}>3F</div>
                        <div onClick={() => viewToggleTab(1)} className={toggle === 1 ? "elevate_button active" : "elevate_button"}>2F</div>
                    </div>
                </div>
            </div>
            <div className={directionToggle === 1 ? "south_view" : "none_south_view"}>
                <div className="view_images">
                    <img src={Room} style={{zIndex: 10}} />
                    <div className={toggle === 1 ? "" : "none_view"}>
                        <img src={S1}  />
                    </div>
                    <div className={toggle === 2 ? "" : "none_view"}>
                        <img src={S2}  />
                    </div>
                    <div className={toggle === 3 ? "" : "none_view"}>
                        <img src={S3}  />
                    </div>
                    <div className={toggle === 4 ? "" : "none_view"}>
                        <img src={S4}  />
                    </div>
                    <div className={toggle === 5 ? "" : "none_view"}>
                        <img src={S5}  />
                    </div>
                    <div className={toggle === 6 ? "" : "none_view"}>
                        <img src={S6}  />
                    </div>
                    <div className={toggle === 7 ? "" : "none_view"}>
                        <img src={S7}  />
                    </div>
                    <div className={toggle === 8 ? "" : "none_view"}>
                        <img src={S8}  />
                    </div>
                    <div className={toggle === 9 ? "" : "none_view"}>
                        <img src={S9}  />
                    </div>
                    <div className={toggle === 10 ? "" : "none_view"}>
                        <img src={S10}  />
                    </div>
                    <div className={toggle === 11 ? "" : "none_view"}>
                        <img src={S11}  />
                    </div>
                    <div className={toggle === 12 ? "" : "none_view"}>
                        <img src={S12}  />
                    </div>
                    <div className={toggle === 13 ? "" : "none_view"}>
                        <img src={S13}  />
                    </div>
                </div>
            </div>
            <div className={directionToggle === 2 ? "south_view" : "none_south_view"}>
                <div className="view_images">
                    <img src={Room} style={{zIndex: 10}} />
                    <div className={toggle === 1 ? "" : "none_view"}>
                        <img src={W1}  />
                    </div>
                    <div className={toggle === 2 ? "" : "none_view"}>
                        <img src={W2}  />
                    </div>
                    <div className={toggle === 3 ? "" : "none_view"}>
                        <img src={W3}  />
                    </div>
                    <div className={toggle === 4 ? "" : "none_view"}>
                        <img src={W4}  />
                    </div>
                    <div className={toggle === 5 ? "" : "none_view"}>
                        <img src={W5}  />
                    </div>
                    <div className={toggle === 6 ? "" : "none_view"}>
                        <img src={W6}  />
                    </div>
                    <div className={toggle === 7 ? "" : "none_view"}>
                        <img src={W7}  />
                    </div>
                    <div className={toggle === 8 ? "" : "none_view"}>
                        <img src={W8}  />
                    </div>
                    <div className={toggle === 9 ? "" : "none_view"}>
                        <img src={W9}  />
                    </div>
                    <div className={toggle === 10 ? "" : "none_view"}>
                        <img src={W10}  />
                    </div>
                    <div className={toggle === 11 ? "" : "none_view"}>
                        <img src={W11}  />
                    </div>
                    <div className={toggle === 12 ? "" : "none_view"}>
                        <img src={W12}  />
                    </div>
                    <div className={toggle === 13 ? "" : "none_view"}>
                        <img src={W13}  />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default View;