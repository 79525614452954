import React, { useState } from "react";
import Title from '../img/roomplan_sozai2/roomplan_title.png'
import Czumen from '../img/room_sozai/C_zu.png'
import Cmm from '../img/roomplan_sozai2/cmm.png'
import Bed from "../img/Csozai/c_bed.jpg"
import Shoose from "../img/Csozai/c_shoose.jpg"
import Bed2 from "../img/Csozai/c_crasic.jpg"
import Wc from "../img/Csozai/c_wc.jpg"
import Bath from "../img/Csozai/c_bath.jpg"
import Wash from "../img/Csozai/c_wash.jpg"
import Japan from "../img/Csozai/c_jap.jpg"
import Living from "../img/Csozai/c_living.jpg"
import TypeButton from "../component/TypeButton";
import MenuList from "../component/MenuList";
import FloorPlanMenu from "../component/FloorPlanMenu";

const RoomPlanC = () => {
  const path = window.location.href
  const id = path.split('/#/')[1]
  const [imgToggle, setImgToggle] = useState(1);
  const [planToggle, setPlanToggle] = useState(0);
  const imgToggleTab = (index) => {
    setImgToggle(index);
  };
    return(
    <div className="root">
      <div className="main_container">
        <div className='room_plan'>
          <h2>ROOM PLAN</h2>
            <div>
              <div className='room_type_view'>
                <img src={Czumen} />
                <div onClick={() => imgToggleTab(8)} className={imgToggle === 8 ? "active_point1_c" : "point1_c"} />
                <div onClick={() => imgToggleTab(2)} className={imgToggle === 2 ? "active_point2_c" : "point2_c"} />
                <div onClick={() => imgToggleTab(3)} className={imgToggle === 3 ? "active_point3_c" : "point3_c"} />
                <div onClick={() => imgToggleTab(4)} className={imgToggle === 4 ? "active_point4_c" : "point4_c"} />
                <div onClick={() => imgToggleTab(5)} className={imgToggle === 5 ? "active_point5_c" : "point5_c"} />
                <div onClick={() => imgToggleTab(6)} className={imgToggle === 6 ? "active_point6_c" : "point6_c"} />
                <div onClick={() => imgToggleTab(7)} className={imgToggle === 7 ? "active_point7_c" : "point7_c"} />
                <div onClick={() => imgToggleTab(1)} className={imgToggle === 1 ? "active_point8_c" : "point8_c"} />
              </div>
              <div className="space_small_module" />
              <img className="room_type_tab" src={Cmm} />
          </div>
        </div>
        <div className='room_view'>
          <div className="room_menu">
            <MenuList id={id} />
            <div className="space_small_module" />
            <TypeButton id={id} />
            <div className="space_small_module" />
            <FloorPlanMenu planToggle={planToggle} setPlanToggle={setPlanToggle} />
          </div>
          <div className="space_small_module" />
          <div className="type_room">
            <div className={imgToggle === 8 ? "active_content" : "contet"}>
              <img src={Bed} />
              <p>寝室</p>
            </div>
            <div className={imgToggle === 2 ? "active_content" : "contet"}>
              <img src={Shoose} />
              <p>玄関</p>
            </div>
            <div className={imgToggle === 3 ? "active_content" : "contet"}>
              <img src={Bed2} />
              <p>寝室</p>
            </div>
            <div className={imgToggle === 4 ? "active_content" : "contet"}>
              <img src={Wc} />
              <p>トイレ</p>
            </div>
            <div className={imgToggle === 5 ? "active_content" : "contet"}>
              <img src={Bath} />
              <p>浴室</p>
            </div>
            <div className={imgToggle === 6 ? "active_content" : "contet"}>
              <img src={Wash} />
              <p>洗面所</p>
            </div>
            <div className={imgToggle === 7 ? "active_content" : "contet"}>
              <img src={Japan} />
              <p>和室</p>
            </div>
            <div className={imgToggle === 1 ? "active_content" : "contet"}>
              <img src={Living} />
              <p>リビング</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
}

export default RoomPlanC;