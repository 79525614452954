import React from "react";
import MapStyle from "../component/MapStyle"
import { GoogleMap, Marker, useLoadScript, InfoWindow } from "@react-google-maps/api"
import Bank from "../img/map icon/bank.png"
import Bus from "../img/map icon/busstop.png"
import Education from "../img/map icon/education.png"
import Medical from "../img/map icon/medical.png"
import Public from "../img/map icon/public.png"
import RailWay from "../img/map icon/railway.png"
import Restaurant from "../img/map icon/restaurant.png"
import Shopping from "../img/map icon/shopping.png"
import Gaikan from "../img/map icon/gaikan-icon.jpg"
import BankB from "../img/icon_single/bank.png"
import BusB from "../img/icon_single/busstop.png"
import EduB from "../img/icon_single/education.png"
import MedB from "../img/icon_single/medical.png"
import PubB from "../img/icon_single/public.png"
import RailB from "../img/icon_single/railway.png"
import RestB from "../img/icon_single/restaurant.png"
import ShopB from "../img/icon_single/shopping.png"
import { useState } from "react";
import { useCallback, useEffect } from "react";
import { useRef } from "react";
import MapMenuButtonList from "../component/MapMenuButtonList";

const containerStyle = {
    width: '100%',
    height: '85vh'
};
const options = {
    styles: MapStyle,
    zoomControl: true,
    disableDefaultUI: false,
}

const libraries = ["places"];
const MapPage = () => {
    const path = window.location.href
    const id = path.split('/#/')[1]
    const {isLoaded, loadError} = useLoadScript({
        googleMapsApiKey: "AIzaSyCCxLZp9h8Jlp_kvQgagGIKe88nQwXXsUY",
        libraries,
    })
    const [center, setCenter] = useState({lat: "", lng: ""});
    const [edToggle, setEdToggle] = useState(true)
    const [meToggle, setMeToggle] = useState(false)
    const [shToggle, setShToggle] = useState(false)
    const [raToggle, setRaToggle] = useState(false)
    const [bsToggle, setBsToggle] = useState(false)
    const [reToggle, setReToggle] = useState(false)
    const [baToggle, setBaToggle] = useState(false)
    const [puToggle, setPuToggle] = useState(false)
    const [makers, setMakers] = useState([]);
    const [selectedPin, setSelectedPin] = useState(null);
    const [selectedEd, setSelectedEd] = useState(null);
    const [selectedSh, setSelectedSh] = useState(null);
    const [selectedMe, setSelectedMe] = useState(null);
    const [selectedRa, setSelectedRa] = useState(null);
    const [selectedBs, setSelectedBs] = useState(null);
    const [selectedRe, setSelectedRe] = useState(null);
    const [selectedBa, setSelectedBa] = useState(null);
    const [selectedPu, setSelectedPu] = useState(null);
    const edClick = () => {
        setEdToggle(!edToggle)
    }
    const meClick = () => {
        setMeToggle(!meToggle)
    }
    const shClick = () => {
        setShToggle(!shToggle)
    }
    const raClick = () => {
        setRaToggle(!raToggle)
    }
    const bsClick = () => {
        setBsToggle(!bsToggle)
    }
    const reClick = () => {
        setReToggle(!reToggle)
    }
    const baClick = () => {
        setBaToggle(!baToggle)
    }
    const puClick = () => {
        setPuToggle(!puToggle)
    }

    const mapRef = useRef();
    const onLoad = useCallback((map) => {
        mapRef.current = map;
    }, [])
    const success = data => {
        const currentPosition = {
            lat: 35.8164188797703,
            lng: 139.57796702650623,
        };
        setCenter(currentPosition);
    }
    const error = data => {
        const currentPosition = {
          lat: 35.81608428070906,
          lng: 139.57773709938652
        };
        setCenter(currentPosition);
    };

    const pin = [
        {id: 0, position: {lat: 35.816117150215796, lng: 139.57764237669457}, name: "匠コーポレーション", minute: "現地から◯◯m", url: Gaikan}, //匠コーポレーション
    ]

    const bank = [
        {id: 1, position: {lat: 35.81836613900264, lng: 139.57270100708314}, name: "埼玉県信用銀行", minute: "現地から◯◯m", url: Bank}, //埼玉県信用銀行
        {id: 2, position: {lat: 35.82047096831981, lng: 139.5752386711558}, name: "ゆうちょ銀行", minute: "現地から◯◯m", url: Bank}, //ゆうちょ銀行
        {id: 3, position: {lat: 35.82087984507002, lng: 139.57474514471576}, name: "みずほ銀行", minute: "現地から◯◯m", url: Bank}, //みずほ銀行
        {id: 4, position: {lat: 35.821323517466276, lng: 139.574830975401}, name: "三菱UFJ", minute: "現地から◯◯m", url: Bank}, //三菱UFJ
        {id: 5, position: {lat: 35.821680193471096, lng: 139.57461639868794}, name: "埼玉りそな銀行", minute: "現地から◯◯m", url: Bank}, 
    ]

    const railway = [
        {id: 1, position: {lat: 35.815467799616634, lng: 139.58714053221914}, name: "朝霞台駅", minute: "現地から◯◯m", url: RailWay}, 
        {id: 2, position: {lat: 35.81661620134197, lng: 139.5876984316731}, name: "北朝霧駅", minute: "現地から◯◯m", url: RailWay}, 
        {id: 3, position: {lat: 35.82260154141271, lng: 139.57521006697297}, name: "志木駅", minute: "現地から◯◯m", url: RailWay}, 
        {id: 4, position: {lat: 35.831439532259665, lng: 139.56212088747628}, name: "柳瀬川駅", minute: "現地から◯◯m", url: RailWay}, 
        {id: 5, position: {lat: 35.804544825957905, lng: 139.55666541986218}, name: "新座駅", minute: "現地から◯◯m", url: RailWay}, 
    ]

    const bus = [
        {id: 1, position: {lat: 35.81793060616008, lng: 139.56531423965495}, name: "北野(バス)", minute: "現地から◯◯m", url: Bus}, 
        {id: 2, position: {lat: 35.81789580692798, lng: 139.56415552540443}, name: "北野(新座市)", minute: "現地から◯◯m", url: Bus}, 
        {id: 3, position: {lat: 35.818400394300404, lng: 139.56155914717638}, name: "富士見ヶ丘団地", minute: "現地から◯◯m", url: Bus}, 
    ]

    const school = [
        {id: 1, position: {lat: 35.81789548956854, lng: 139.56870455186797}, name: "新座市立東北小学校", minute: "現地から◯◯m", url: Education}, 
        {id: 2, position: {lat: 35.811074545572694, lng: 139.58059210177151}, name: "朝霞市立朝霧第五小学校", minute: "現地から◯◯m", url: Education}, 
        {id: 3, position: {lat: 35.81995731775573, lng: 139.58637494418846}, name: "朝霞市立朝霧第七小学校", minute: "現地から◯◯m", url: Education}, 
        {id: 4, position: {lat: 35.80867611544922, lng: 139.5673101088278}, name: "新座市立東野小学校", minute: "現地から◯◯m", url: Education}, 
        {id: 5, position: {lat: 35.818695417531934, lng: 139.579961815442}, name: "三原どろんこ保育園", minute: "現地から◯◯m", url: Education}, 
        {id: 6, position: {lat: 35.81970457890519, lng: 139.57412532884678}, name: "かきの木幼稚園", minute: "現地から◯◯m", url: Education}, 
        {id: 7, position: {lat: 35.821374886781946, lng: 139.5657568370374}, name: "志木なかもり幼稚園", minute: "現地から◯◯m", url: Education}, 
        {id: 8, position: {lat: 35.81392782656231, lng: 139.58343795819357}, name: "朝霞ゆりかご保育園", minute: "現地から◯◯m", url: Education}, 
        {id: 9, position: {lat: 35.81566785644244, lng: 139.58326629682313}, name: "さいか幼稚園", minute: "現地から◯◯m", url: Education}, 
        {id: 10, position: {lat: 35.81100449052086, lng: 139.56996254061337}, name: "わびのび幼稚園", minute: "現地から◯◯m", url: Education}, 
        {id: 11, position: {lat: 35.81594562303698, lng: 139.59781459690296}, name: "朝霞市立朝霧第二中学校", minute: "現地から◯◯m", url: Education}, 
        {id: 12, position: {lat: 35.82534109255825, lng: 139.59712795142121}, name: "朝霞市立朝霧第五中学校", minute: "現地から◯◯m", url: Education}, 
        {id: 13, position: {lat: 35.815536720873354, lng: 139.56691555008246}, name: "立教新座中学校・高等学校", minute: "現地から◯◯m", url: Education}, 
        {id: 14, position: {lat: 35.815536720873354, lng: 139.56691555008246}, name: "志木市立宗岡第二中学校", minute: "現地から◯◯m", url: Education}, 
        {id: 15, position: {lat: 35.83487461730208, lng: 139.5695763013244}, name: "志木市立式中学校", minute: "現地から◯◯m", url: Education}, 
        {id: 16, position: {lat: 35.821524960203284, lng: 139.57450137174712}, name: "鹿島学園高等学校", minute: "現地から◯◯m", url: Education}, 
        {id: 17, position: {lat: 35.824687599047365, lng: 139.57863376108295}, name: "慶應義塾志木高等学校", minute: "現地から◯◯m", url: Education}, 
        {id: 18, position: {lat: 35.82811710051397, lng: 139.57211442836086}, name: "むさしの高等学院", minute: "現地から◯◯m", url: Education}, 
    ]

    const medical = [
        {id: 1, position: {lat: 35.82073662747605, lng: 139.57109587981302}, name: "新座志木中央総合病院", minute: "現地から◯◯m", url: Medical}, 
        {id: 2, position: {lat: 35.81697835511402, lng: 139.58549397725938}, name: "朝霞台クリニック", minute: "現地から◯◯m", url: Medical}, 
        {id: 3, position: {lat: 35.8116886333241, lng: 139.5841850593593}, name: "朝霞透析クリニック", minute: "現地から◯◯m", url: Medical}, 
        {id: 4, position: {lat: 35.81232376652163, lng: 139.5885195090062}, name: "朝霧厚生病院", minute: "現地から◯◯m", url: Medical}, 
    ]

    const restaurant = [
        {id: 1, position: {lat: 35.81619955063961, lng: 139.57674997594492}, name: "どんぐり", minute: "現地から◯◯m", url: Restaurant}, 
        {id: 2, position: {lat: 35.81469443709899, lng: 139.5811809850696}, name: "蘭", minute: "現地から◯◯m", url: Restaurant}, 
        {id: 3, position: {lat: 35.81417, lng: 139.5822538686349}, name: "デニーズ朝霞台店", minute: "現地から◯◯m", url: Restaurant}, 
        {id: 4, position: {lat: 35.81324290342178, lng: 139.5764353125842}, name: "焼肉きんぐ朝霞三原店", minute: "現地から◯◯m", url: Restaurant}, 
        {id: 5, position: {lat: 35.81404332880713, lng: 139.57674644881814}, name: "焼肉安楽亭朝霞三原店", minute: "現地から◯◯m", url: Restaurant}, 
        {id: 6, position: {lat: 35.822590846720445, lng: 139.57353852697742}, name: "ガスト志木駅前店", minute: "現地から◯◯m", url: Restaurant}, 
        {id: 7, position: {lat: 35.80757933548824, lng: 139.5768000866124}, name: "マクドナルド", minute: "現地から◯◯m", url: Restaurant}, 
    ]

    const shopping = [
        {id: 1, position: {lat: 35.82091992979484, lng: 139.57474015566265}, name: "イオン新座店", minute: "現地から◯◯m", url: Shopping}, 
        {id: 2, position: {lat: 35.808948544828226, lng: 139.5791604359517}, name: "スーパーバリュー朝霞泉水店", minute: "現地から◯◯m", url: Shopping}, 
        {id: 3, position: {lat: 35.80337984382094, lng: 139.57074902879972}, name: "西友新座店", minute: "現地から◯◯m", url: Shopping}, 
        {id: 4, position: {lat: 35.8154216689683, lng: 139.58615563679746}, name: "東武ストア", minute: "現地から◯◯m", url: Shopping}, 
        {id: 5, position: {lat: 35.81405510986106, lng: 139.57152150398733}, name: "ユニクロ", minute: "現地から◯◯m", url: Shopping}, 
    ]

    const pub = [
        {id: 1, position: {lat: 35.82105848673356, lng: 139.57216523397688}, name: "新座市役所東北出張所", minute: "現地から◯◯m", url: Public}, 
        {id: 2, position: {lat: 35.82105848673356, lng: 139.57216523397688}, name: "朝霞市役所エコネットあさか", minute: "現地から◯◯m", url: Public}, 
        {id: 3, position: {lat: 35.811384094068735, lng: 139.58845160649818}, name: "朝霞市役所子育て電話相談室", minute: "現地から◯◯m", url: Public}, 
        {id: 4, position: {lat: 35.8205252792971, lng: 139.5847394249956}, name: "朝霞市北朝霞公民館", minute: "現地から◯◯m", url: Public}, 
        {id: 5, position: {lat: 35.80312432911578, lng: 139.57958958388215}, name: "西朝霞公民館", minute: "現地から◯◯m", url: Public}, 
    ]
    useEffect(() => {
        navigator.geolocation.getCurrentPosition(success, error)
    },[])

    const onMapClick = useCallback((event) => {
        setMakers(current => [...current, {
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
            time: new Date()
        }])
    }, []);

    const handleActiveMarker = (marker) => {
        if (marker === selectedPin) {
          return;
        }
        setSelectedPin(marker);
    };
    const handleActiveMarkerEd = (marker) => {
        if (marker === selectedEd) {
          return;
        }
        setSelectedEd(marker);
    };
    const handleActiveMarkerMe = (marker) => {
        if (marker === selectedMe) {
          return;
        }
        setSelectedMe(marker);
    };
    const handleActiveMarkerSh = (marker) => {
        if (marker === selectedSh) {
          return;
        }
        setSelectedSh(marker);
    };
    const handleActiveMarkerRa = (marker) => {
        if (marker === selectedRa) {
          return;
        }
        setSelectedRa(marker);
    };
    const handleActiveMarkerBS = (marker) => {
        if (marker === selectedBs) {
          return;
        }
        setSelectedBs(marker);
    };
    const handleActiveMarkerRE = (marker) => {
        if (marker === selectedRe) {
          return;
        }
        setSelectedRe(marker);
    };
    const handleActiveMarkerBa = (marker) => {
        if (marker === selectedBa) {
          return;
        }
        setSelectedBa(marker);
    };
    const handleActiveMarkerPu = (marker) => {
        if (marker === selectedPu) {
          return;
        }
        setSelectedPu(marker);
    };

    if(loadError) return "Error loading Maps"
    if(!isLoaded) return "Loading Maps"

    return (
        <div className="map_root"> 
            <div className="map">
                <div className="map_view">
                    <h2>MAP</h2>
                    <div className="space_small_module" />
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={16}
                        options={options}
                        onLoad={onLoad}
                        onClick={onMapClick}
                    >
                    {pin.map(({id, position, name, minute, url}) => (
                        <Marker
                            key={id}
                            position={position}
                            icon={{
                                url: url,
                                anchor: new window.google.maps.Point(30, 46),
                                scaledSize: new window.google.maps.Size(50, 50),
                                origin: new window.google.maps.Point(0, 0),
                            }}
                            onClick={() => handleActiveMarker(id)}
                        >
                            {selectedPin === id ? (
                                <InfoWindow position={position} onCloseClick={() => setSelectedPin(null)}>
                                    <div>
                                        <h3>{name}</h3>
                                    </div>
                                </InfoWindow>
                            ): null}
                        </Marker>
                    ))}
                        {edToggle === true ? school.map(({id, position, name, minute, url}) => (
                            <Marker
                                key={id}
                                position={position}
                                icon={{
                                    url: url,
                                    anchor: new window.google.maps.Point(30, 46),
                                    scaledSize: new window.google.maps.Size(70, 56),
                                    origin: new window.google.maps.Point(0, 0),
                                }}
                                onClick={() => handleActiveMarkerEd(id)}
                            >
                                {selectedEd === id ? (
                                    <InfoWindow position={position} onCloseClick={() => setSelectedPin(null)}>
                                        <div>
                                            <h3>{name}</h3>
                                            <p>{minute}</p>
                                        </div>
                                    </InfoWindow>
                                ): null}
                            </Marker>
                        )) : null}
                        {meToggle === true ? medical.map(({id, position, name, minute, url}) => (
                            <Marker
                                key={id}
                                position={position}
                                icon={{
                                    url: url,
                                    anchor: new window.google.maps.Point(30, 46),
                                    scaledSize: new window.google.maps.Size(70, 56),
                                    origin: new window.google.maps.Point(0, 0),
                                }}
                                onClick={() => handleActiveMarkerMe(id)}
                            >
                                {selectedMe === id ? (
                                    <InfoWindow position={position} onCloseClick={() => setSelectedPin(null)}>
                                        <div>
                                            <h3>{name}</h3>
                                            <p>{minute}</p>
                                        </div>
                                    </InfoWindow>
                                ): null}
                            </Marker>
                        )) : null}
                        {shToggle === true ? shopping.map(({id, position, name, minute, url}) => (
                            <Marker
                                key={id}
                                position={position}
                                icon={{
                                    url: url,
                                    anchor: new window.google.maps.Point(30, 46),
                                    scaledSize: new window.google.maps.Size(70, 56),
                                    origin: new window.google.maps.Point(0, 0),
                                }}
                                onClick={() => handleActiveMarkerSh(id)}
                            >
                                {selectedSh === id ? (
                                    <InfoWindow position={position} onCloseClick={() => setSelectedPin(null)}>
                                        <div>
                                            <h3>{name}</h3>
                                            <p>{minute}</p>
                                        </div>
                                    </InfoWindow>
                                ): null}
                            </Marker>
                        )) : null}
                        {raToggle === true ? railway.map(({id, position, name, minute, url}) => (
                            <Marker
                                key={id}
                                position={position}
                                icon={{
                                    url: url,
                                    anchor: new window.google.maps.Point(30, 46),
                                    scaledSize: new window.google.maps.Size(70, 56),
                                    origin: new window.google.maps.Point(0, 0),
                                }}
                                onClick={() => handleActiveMarkerRa(id)}
                            >
                                {selectedRa === id ? (
                                    <InfoWindow position={position} onCloseClick={() => setSelectedPin(null)}>
                                        <div>
                                            <h3>{name}</h3>
                                            <p>{minute}</p>
                                        </div>
                                    </InfoWindow>
                                ): null}
                            </Marker>
                        )) : null}
                        {bsToggle === true ? bus.map(({id, position, name, minute, url}) => (
                            <Marker
                                key={id}
                                position={position}
                                icon={{
                                    url: url,
                                    anchor: new window.google.maps.Point(30, 46),
                                    scaledSize: new window.google.maps.Size(70, 56),
                                    origin: new window.google.maps.Point(0, 0),
                                }}
                                onClick={() => handleActiveMarkerBS(id)}
                            >
                                {selectedBs === id ? (
                                    <InfoWindow position={position} onCloseClick={() => setSelectedPin(null)}>
                                        <div>
                                            <h3>{name}</h3>
                                            <p>{minute}</p>
                                        </div>
                                    </InfoWindow>
                                ): null}
                            </Marker>
                        )) : null}
                        {reToggle === true ? restaurant.map(({id, position, name, minute, url}) => (
                            <Marker
                                key={id}
                                position={position}
                                icon={{
                                    url: url,
                                    anchor: new window.google.maps.Point(30, 46),
                                    scaledSize: new window.google.maps.Size(70, 56),
                                    origin: new window.google.maps.Point(0, 0),
                                }}
                                onClick={() => handleActiveMarkerRE(id)}
                            >
                                {selectedRe === id ? (
                                    <InfoWindow onCloseClick={() => setSelectedRe(null)}>
                                        <div>
                                            <h3>{name}</h3>
                                            <p>{minute}</p>
                                        </div>
                                    </InfoWindow>
                                ): null}
                            </Marker>
                        )) : null}
                        {baToggle === true ? bank.map(({id, position, name, minute, url}) => (
                            <Marker
                                key={id}
                                position={position}
                                icon={{
                                    url: url,
                                    anchor: new window.google.maps.Point(30, 46),
                                    scaledSize: new window.google.maps.Size(70, 56),
                                    origin: new window.google.maps.Point(0, 0),
                                }}
                                onClick={() => handleActiveMarkerBa(id)}
                            >
                                {selectedBa === id ? (
                                    <InfoWindow position={position} onCloseClick={() => setSelectedPin(null)}>
                                        <div>
                                            <h3>{name}</h3>
                                            <p>{minute}</p>
                                        </div>
                                    </InfoWindow>
                                ): null}
                            </Marker>
                        )) : null}
                        {puToggle === true ? pub.map(({id, position, name, minute, url}) => (
                            <Marker
                                key={id}
                                position={position}
                                icon={{
                                    url: url,
                                    anchor: new window.google.maps.Point(30, 46),
                                    scaledSize: new window.google.maps.Size(70, 56),
                                    origin: new window.google.maps.Point(0, 0),
                                }}
                                onClick={() => handleActiveMarkerPu(id)}
                            >
                                {selectedPu === id ? (
                                    <InfoWindow position={position} onCloseClick={() => setSelectedPin(null)}>
                                        <div>
                                            <h3>{name}</h3>
                                            <p>{minute}</p>
                                        </div>
                                    </InfoWindow>
                                ): null}
                            </Marker>
                        )) : null}
                    </GoogleMap>
                </div>
                <div className="map_sub_menu">
                    <div className="map_button_root">
                        <div className="map_button">
                            <div onClick={() => edClick()} className={edToggle === true ? "map_button_item ed active" : "map_button_item ed"}>
                                <img src={EduB} />
                                <div className="map_border ed" />
                                <p>EDUCATION</p>
                            </div>
                            <div onClick={() => meClick()}  className={meToggle === true ? "map_button_item me active" : "map_button_item me"}>
                                <img src={MedB} />
                                <div className="map_border me" />
                                <p>MEDICAL</p>
                            </div>
                        </div>
                        <div className="map_button">
                            <div onClick={() => shClick()} className={shToggle === true ? "map_button_item sh active" : "map_button_item sh"}>
                                <img src={ShopB} />
                                <div className="map_border sh" />
                                <p>SHOPPING</p>
                            </div>
                            <div onClick={() => raClick()} className={raToggle === true ? "map_button_item ra active" : "map_button_item ra"}>
                                <img src={RailB} />
                                <div className="map_border ra"  />
                                <p>RAIL WAY</p>
                            </div>
                        </div>
                        <div className="map_button">
                            <div onClick={() => bsClick()} className={bsToggle === true ? "map_button_item bs active" : "map_button_item bs"}>
                                <img src={BusB} />
                                <div className="map_border bs"  />
                                <p>BUS STOP</p>
                            </div>
                            <div onClick={() => reClick()} className={reToggle === true ? "map_button_item re active" : "map_button_item re"}>
                                <img src={RestB} />
                                <div className="map_border re" />
                                <p>RESTAURANT</p>
                            </div>
                        </div>
                        <div className="map_button">
                            <div onClick={() => baClick()} className={baToggle === true ? "map_button_item ba active" : "map_button_item ba"}>
                                <img src={BankB} />
                                <div className="map_border ba"  />
                                <p>BANK</p>
                            </div>
                            <div onClick={() => puClick()} className={puToggle === true ? "map_button_item pu active" : "map_button_item pu"}>
                                <img src={PubB} />
                                <div className="map_border pu"  />
                                <p>PUBLIC</p>
                            </div>
                        </div>
                    </div>
                    <div className="space_small_module" />
                    <MapMenuButtonList id={id} />
                </div>
            </div>
        </div>
    )
}

export default MapPage;