import React, { useState } from "react";
import LivingDinningImage1 from "../../img/color_select1/living_1.png"
import LivingDinningImage2 from "../../img/color_select1/living_2.png"
import LivingDinningImage3 from "../../img/color_select1/living_3.png"
import LivingDinningImage4 from "../../img/color_select1/living_4.png"
import LivingDinningImage5 from "../../img/color_select1/living_5.png"
import Liv1 from "../../img/color_select1/liv1ico.jpg"
import Liv2 from "../../img/color_select1/liv2ico.jpg"
import Liv3 from "../../img/color_select1/liv3ico.jpg"
import Liv4 from "../../img/color_select1/liv4ico.jpg"
import Liv5 from "../../img/color_select1/liv5ico.jpg"
import ColorSelectNav from "../../component/ColorSelectNav";
const LivingDining = () => {
    const path = window.location.href
    const id = path.split('/#/')[1]
    const [panelToggle, setPanelToggle] = useState(1);
    const panelToggleTab = (index) => {
        setPanelToggle(index);
    };
    return(
        <div className="color_select_root">
            <div className="select_view">
                <img className={panelToggle === 5 ? "panel_show active" : "panel_show"} src={LivingDinningImage1} />
                <img className={panelToggle === 2 ? "panel_show active" : "panel_show"} src={LivingDinningImage2} />
                <img className={panelToggle === 3 ? "panel_show active" : "panel_show"} src={LivingDinningImage3} />
                <img className={panelToggle === 4 ? "panel_show active" : "panel_show"} src={LivingDinningImage4} />
                <img className={panelToggle === 1 ? "panel_show active" : "panel_show"} src={LivingDinningImage5} />
            </div>
            <div className="select_menu">
                <div>
                    <div className="space_large_module"/>
                    <p>FLOORING/DOOR</p>
                    <div className="slect_plat">
                        <img onClick={() => panelToggleTab(1)} src={Liv5} />
                        <img onClick={() => panelToggleTab(4)} src={Liv4} />
                        <img onClick={() => panelToggleTab(2)} src={Liv2} />
                        <img onClick={() => panelToggleTab(3)} src={Liv3} />
                        <img onClick={() => panelToggleTab(5)} src={Liv1} />
                    </div>
                </div>
                <ColorSelectNav id={id} />
            </div>
        </div>
    )
}

export default LivingDining;