import React from "react";
import RoomPlanText from "../img/room_sozai/roomplan.png"
import FloorViewText from "../img/room_sozai/floorview.png"
import LoanText from "../img/room_sozai/loan.png"
import PriceText from "../img/room_sozai/price.png"
import VrTourText from "../img/room_sozai/vrtour.png"
import { useNavigate } from "react-router-dom";

const MenuList = (props) => {
    const id = props.id;
    const navigate = useNavigate();
    return(
        <div className="menu_list_wrapper">
            <div className={id === "roomplan" || id === "siteplan" || id === "elevationplan" || id === "roomplan_a" || id === "roomplan_b" || id === "roomplan_c" || id ===  "roomplan_d" || id === "roomplan_e" ? "menu_button active" : "menu_button"}>
                <button onClick={() => navigate('/roomplan_a')}>
                    <img src={RoomPlanText} />
                </button>
            </div>
            <div className={id === "simulate" ? "menu_button active" : "menu_button" }>
                <button onClick={() => navigate('/simulate')}>
                    <img src={LoanText} />
                </button>
            </div>
            <div className={id === "" ? "menu_button active" : "menu_button"}>
                <button onClick={() => navigate('/')}>
                    <img src={PriceText} />
                </button>
            </div>
            <div className={id === "view" ? "menu_button active" : "menu_button"}>
                <button onClick={() => navigate('/view')}><img src={FloorViewText} /></button>
            </div>
            <div className="menu_button">
                <a href="https://www.wcvr1.com/vrmr1">
                    <button><img src={VrTourText} /></button>
                </a>
            </div>
        </div>
    )
}

export default MenuList;