import React from "react";
import { Modal } from "@mui/material";
import PointViewUp from "../img/landscape/p07_2_cut.jpg"
import PointViewUp2 from "../img/landscape//p07_4_cut.jpg"
import View from "../img/landscape/g099.jpg"
import View1 from "../img/landscape/view1.jpg"
import View2 from "../img/landscape/view2.jpg"
import View3 from "../img/landscape/view3.jpg"
import View4 from "../img/landscape/view4.jpg"
import View5 from "../img/landscape/view5.jpg"
import View6 from "../img/landscape/view6.jpg"
import View7 from "../img/landscape/view7.jpg"
import View8 from "../img/landscape/view8.jpg"
import View9 from "../img/landscape/view9.jpg"
import View10 from "../img/landscape/view10.jpg"
import View11 from "../img/landscape/view11.jpg"
import View12 from "../img/landscape/view12.jpg"
import View13 from "../img/landscape/view13.jpg"
import View14 from "../img/landscape/view14.jpg"
import View15 from "../img/landscape/view15.jpg"
import View16 from "../img/landscape/view16.jpg"
import View17 from "../img/landscape/view17.jpg"
import View18 from "../img/landscape/view18.jpg"
import View19 from "../img/landscape/view19.jpg"
import View20 from "../img/landscape/view20.jpg"
import View21 from "../img/landscape/view21.jpg"
import View22 from "../img/landscape/view22.jpg"
import View23 from "../img/landscape/view23.jpg"
import View24 from "../img/landscape/view24.jpg"
import View25 from "../img/landscape/view25.jpg"
import View26 from "../img/landscape/view26.jpg"
import View27 from "../img/landscape/view27.jpg"
import View28 from "../img/landscape/view28.jpg"
import { useState } from "react";
import { Box } from "@mui/system";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
    backgroundColor: "#fff"
};

const LandScape = () => {
    const [toggle, setToggle] = useState(1)
    const [open1, setOpen1] = useState(false);
    const handleOpen1 = () => setOpen1(true);
    const handleClose1 = () => setOpen1(false);
    const [open2, setOpen2] = useState(false);
    const handleOpen2 = () => setOpen2(true);
    const handleClose2 = () => setOpen2(false);
    const [open3, setOpen3] = useState(false);
    const handleOpen3 = () => setOpen3(true);
    const handleClose3 = () => setOpen3(false);
    const [open4, setOpen4] = useState(false);
    const handleOpen4 = () => setOpen4(true);
    const handleClose4 = () => setOpen4(false);
    const [open5, setOpen5] = useState(false);
    const handleOpen5 = () => setOpen5(true);
    const handleClose5 = () => setOpen5(false);
    const [open6, setOpen6] = useState(false);
    const handleOpen6 = () => setOpen6(true);
    const handleClose6 = () => setOpen6(false);
    const [open7, setOpen7] = useState(false);
    const handleOpen7 = () => setOpen7(true);
    const handleClose7 = () => setOpen7(false);
    const [open8, setOpen8] = useState(false);
    const handleOpen8 = () => setOpen8(true);
    const handleClose8 = () => setOpen8(false);
    const [open9, setOpen9] = useState(false);
    const handleOpen9 = () => setOpen9(true);
    const handleClose9 = () => setOpen9(false);
    const [open10, setOpen10] = useState(false);
    const handleOpen10 = () => setOpen10(true);
    const handleClose10 = () => setOpen10(false);
    const [open11, setOpen11] = useState(false);
    const handleOpen11 = () => setOpen11(true);
    const handleClose11 = () => setOpen11(false);
    const [open12, setOpen12] = useState(false);
    const handleOpen12 = () => setOpen12(true);
    const handleClose12 = () => setOpen12(false);
    const [open13, setOpen13] = useState(false);
    const handleOpen13 = () => setOpen13(true);
    const handleClose13 = () => setOpen13(false);
    const [open14, setOpen14] = useState(false);
    const handleOpen14 = () => setOpen14(true);
    const handleClose14 = () => setOpen14(false);
    const [open15, setOpen15] = useState(false);
    const handleOpen15 = () => setOpen15(true);
    const handleClose15 = () => setOpen15(false);
    const [open16, setOpen16] = useState(false);
    const handleOpen16 = () => setOpen16(true);
    const handleClose16 = () => setOpen16(false);
    const [open17, setOpen17] = useState(false);
    const handleOpen17 = () => setOpen17(true);
    const handleClose17 = () => setOpen17(false);
    const [open18, setOpen18] = useState(false);
    const handleOpen18 = () => setOpen18(true);
    const handleClose18 = () => setOpen18(false);
    const [open19, setOpen19] = useState(false);
    const handleOpen19 = () => setOpen19(true);
    const handleClose19 = () => setOpen19(false);
    const [open20, setOpen20] = useState(false);
    const handleOpen20 = () => setOpen20(true);
    const handleClose20 = () => setOpen20(false);
    const [open21, setOpen21] = useState(false);
    const handleOpen21 = () => setOpen21(true);
    const handleClose21 = () => setOpen21(false);
    const [open22, setOpen22] = useState(false);
    const handleOpen22 = () => setOpen22(true);
    const handleClose22 = () => setOpen22(false);
    const [open23, setOpen23] = useState(false);
    const handleOpen23 = () => setOpen23(true);
    const handleClose23 = () => setOpen23(false);
    const [open24, setOpen24] = useState(false);
    const handleOpen24 = () => setOpen24(true);
    const handleClose24 = () => setOpen24(false);
    const [open25, setOpen25] = useState(false);
    const handleOpen25 = () => setOpen25(true);
    const handleClose25 = () => setOpen25(false);
    const [open26, setOpen26] = useState(false);
    const handleOpen26 = () => setOpen26(true);
    const handleClose26 = () => setOpen26(false);
    const [open27, setOpen27] = useState(false);
    const handleOpen27 = () => setOpen27(true);
    const handleClose27 = () => setOpen27(false);
    const [open28, setOpen28] = useState(false);
    const handleOpen28 = () => setOpen28(true);
    const handleClose28 = () => setOpen28(false);
    const imageToggleTab = (index) => {
        setToggle(index)
    }
    return(
        <div className="land_root">
            <h2>LAND SCAPE</h2>
            <div className="land_scape">
                <div className="scape_point">
                    <div className="point_view">
                        <div className={toggle === 1 ? "point_dotto" : "none_view"}>
                            <img src={View} />
                            <div onClick={handleOpen1} className={"dotto1"}>
                            </div>
                            <Modal
                                open={open1}
                                onClose={handleClose1}
                            >
                                <Box sx={style}>
                                    <img onClick={handleClose1} style={{width: "100%", border: "none"}} src={View1} />
                                </Box>
                            </Modal>
                            <div onClick={handleOpen2}  className="dotto2">
                            </div>
                            <Modal
                                open={open2}
                                onClose={handleClose2}
                            >
                                <Box sx={style}>
                                    <img onClick={handleClose2} style={{width: "100%"}} src={View2} />
                                </Box>
                            </Modal>
                            <div onClick={handleOpen3}  className="dotto3">
                            </div>
                            <Modal
                                open={open3}
                                onClose={handleClose3}
                            >
                                <Box sx={style}>
                                    <img onClick={handleClose3} style={{width: "100%"}} src={View3} />
                                </Box>
                            </Modal>
                            <div onClick={handleOpen4}  className={"dotto4"}>
                            </div>
                            <Modal
                                open={open4}
                                onClose={handleClose4}
                            >
                                <Box sx={style}>
                                    <img onClick={handleClose4} style={{width: "100%"}} src={View4} />
                                </Box>
                            </Modal>
                            <div onClick={handleOpen5}  className={"dotto5"}>
                            </div>
                            <Modal
                                open={open5}
                                onClose={handleClose5}
                            >
                                <Box sx={style}>
                                    <img onClick={handleClose5} style={{width: "80%", margin: "0 auto"}} src={View6} />
                                </Box>
                            </Modal>
                            <div onClick={handleOpen6}  className={"dotto6"}>
                            </div>
                            <Modal
                                open={open6}
                                onClose={handleClose6}
                            >
                                <Box sx={style}>
                                    <img onClick={handleClose6} style={{width: "100%"}} src={View7} />
                                </Box>
                            </Modal>
                            <div onClick={handleOpen7}  className={"dotto7"}>
                            </div>
                            <Modal
                                open={open7}
                                onClose={handleClose7}
                            >
                                <Box sx={style}>
                                    <img onClick={handleClose7} style={{width: "70%", margin: "0 auto"}} src={View8} />
                                </Box>
                            </Modal>
                            <div onClick={handleOpen8}  className={"dotto8"}>
                            </div>
                            <Modal
                                open={open8}
                                onClose={handleClose8}
                            >
                                <Box sx={style}>
                                    <img onClick={handleClose8} style={{width: "100%"}} src={View9} />
                                </Box>
                            </Modal>
                            <div onClick={handleOpen9}  className={"dotto9"}>
                            </div>
                            <Modal
                                open={open9}
                                onClose={handleClose9}
                            >
                                <Box sx={style}>
                                    <img onClick={handleClose9} style={{width: "100%"}} src={View10} />
                                </Box>
                            </Modal>
                            <div onClick={handleOpen10}  className={"dotto10"}>
                            </div>
                            <Modal
                                open={open10}
                                onClose={handleClose10}
                            >
                                <Box sx={style}>
                                    <img onClick={handleClose10} style={{width: "100%"}} src={View11} />
                                </Box>
                            </Modal>
                            <div onClick={handleOpen11}  className={"dotto11"}>
                            </div>
                            <Modal
                                open={open11}
                                onClose={handleClose11}
                            >
                                <Box sx={style}>
                                    <img onClick={handleClose11} style={{width: "100%"}} src={View12} />
                                </Box>
                            </Modal>
                            <div onClick={handleOpen12}  className={"dotto12"}>
                            </div>
                            <Modal
                                open={open12}
                                onClose={handleClose12}
                            >
                                <Box sx={style}>
                                    <img onClick={handleClose12} style={{width: "100%"}} src={View13} />
                                </Box>
                            </Modal>
                            <div onClick={handleOpen13}  className={"dotto13"} >
                            </div>
                            <Modal
                                open={open13}
                                onClose={handleClose13}
                            >
                                <Box sx={style}>
                                    <img onClick={handleClose13} style={{width: "100%"}} src={View14} />
                                </Box>
                            </Modal>
                            </div>
                    </div>
                    <div className="point_view2">
                        <div className={toggle === 2 ? "point_dotto2" : "none_view"}>
                            <img className={toggle === 2 ? "pop_view" : "none_view"} src={PointViewUp} />
                            <div onClick={handleOpen14}  className={"dotto14"}>
                            </div>
                            <Modal
                                open={open14}
                                onClose={handleClose14}
                            >
                                <Box sx={style}>
                                    <img onClick={handleClose14} style={{width: "100%"}} src={View5} />
                                </Box>
                            </Modal>
                            <div onClick={handleOpen15}  className={"dotto15"}>
                            </div>
                            <Modal
                                open={open15}
                                onClose={handleClose15}
                            >
                                <Box sx={style}>
                                    <img onClick={handleClose15} style={{width: "100%"}} src={View15} />
                                </Box>
                            </Modal>
                            <div onClick={handleOpen16}  className={"dotto16"}>
                            </div>
                            <Modal
                                open={open16}
                                onClose={handleClose16}
                            >
                                <Box sx={style}>
                                    <img  onClick={handleClose16}style={{width: "100%"}} src={View16} />
                                </Box>
                            </Modal>
                            <div onClick={handleOpen17}  className={"dotto17"}>
                            </div>
                            <Modal
                                open={open17}
                                onClose={handleClose17}
                            >
                                <Box sx={style}>
                                    <img onClick={handleClose17} style={{width: "100%"}} src={View17} />
                                </Box>
                            </Modal>
                            <div onClick={handleOpen18}  className={"dotto18"} >
                            </div>
                            <Modal
                                open={open18}
                                onClose={handleClose18}
                            >
                                <Box sx={style}>
                                    <img onClick={handleClose18} style={{width: "100%"}} src={View19} />
                                </Box>
                            </Modal>
                            <div onClick={handleOpen19} className={"dotto19"}>
                            </div>
                                <Modal
                                    open={open19}
                                    onClose={handleClose19}
                                >
                                    <Box sx={style}>
                                        <img onClick={handleClose19} style={{width: "100%"}} src={View18} />
                                    </Box>
                                </Modal>
                        </div>
                    </div>
                    <div className="point_view3">
                        <div className={toggle === 3 ? "point_dotto3" : "none_view"}>
                            <img src={PointViewUp2} />
                            <div onClick={handleOpen20} className="dotto20">
                            </div>
                                <Modal
                                    open={open20}
                                    onClose={handleClose20}
                                >
                                    <Box sx={style}>
                                        <img onClick={handleClose20} style={{width: "100%"}} src={View20} />
                                    </Box>
                                </Modal>
                            <div onClick={handleOpen21} className="dotto21">
                            </div>
                                <Modal
                                    open={open21}
                                    onClose={handleClose21}
                                >
                                    <Box sx={style}>
                                        <img onClick={handleClose21} style={{width: "100%"}} src={View21} />
                                    </Box>
                                </Modal>
                            <div onClick={handleOpen22} className="dotto22">
                            </div>
                                <Modal
                                    open={open22}
                                    onClose={handleClose22}
                                >
                                    <Box sx={style}>
                                        <img onClick={handleClose22} style={{width: "100%"}} src={View22} />
                                    </Box>
                                </Modal>
                            <div onClick={handleOpen23} className="dotto23">
                            </div>
                                <Modal
                                    open={open23}
                                    onClose={handleClose23}
                                >
                                    <Box sx={style}>
                                        <img onClick={handleClose23} style={{width: "100%"}} src={View23} />
                                    </Box>
                                </Modal>
                            <div onClick={handleOpen24} className="dotto24">
                            </div>
                                <Modal
                                    open={open24}
                                    onClose={handleClose24}
                                >
                                    <Box sx={style}>
                                        <img onClick={handleClose24} style={{width: "100%"}} src={View24} />
                                    </Box>
                                </Modal>
                            <div onClick={handleOpen25} className="dotto25">
                            </div>
                                <Modal
                                    open={open25}
                                    onClose={handleClose25}
                                >
                                    <Box sx={style}>
                                        <img onClick={handleClose25} style={{width: "100%"}} src={View25} />
                                    </Box>
                                </Modal>
                            <div onClick={handleOpen26} className="dotto26">
                            </div>
                                <Modal
                                    open={open26}
                                    onClose={handleClose26}
                                >
                                    <Box sx={style}>
                                        <img onClick={handleClose26} style={{width: "100%"}} src={View26} />
                                    </Box> 
                                </Modal>
                            <div onClick={handleOpen27} className="dotto27">
                            </div>
                                <Modal
                                    open={open27}
                                    onClose={handleClose27}
                                >
                                    <Box sx={style}>
                                        <img onClick={handleClose27} style={{width: "100%"}} src={View27} />
                                    </Box>
                                </Modal>
                            <div onClick={handleOpen28} className="dotto28">
                            </div>
                                <Modal
                                    open={open28}
                                    onClose={handleClose28}
                                >
                                    <Box sx={style}>
                                        <img onClick={handleClose28} style={{width: "100%"}} src={View28} />
                                    </Box>
                                </Modal>
                        </div>
                    </div>
                </div>
                <div className="acv_view">
                    <img onClick={() => imageToggleTab(1)} className={toggle === 1 ? "acv_thumb" : "none_thumb"} src={View} />
                    <img onClick={() => imageToggleTab(2)} className={toggle === 2 ? "acv_thumb" : "none_thumb"} src={PointViewUp} />
                    <img onClick={() => imageToggleTab(3)} className={toggle === 3 ? "acv_thumb" : "none_thumb"} src={View27} />
                </div>
            </div>
        </div>
    )
}

export default LandScape