import React from "react";
import BtypeEntrance from "../../img/Btype/B1_entrance.jpg";
import BtypeLiving from "../../img/Btype/B1_living.jpg";
import BtypeBed1 from "../../img/Btype/B1_bed1.jpg";
import BtypeBed2 from "../../img/Btype/B1_bed2.jpg";
import BtypeBed3 from "../../img/Btype/B1_bed3.jpg";
import BtypeBath from "../../img/Btype/B1_bath.jpg";
import BtypeLav from "../../img/Btype/B1_lavatory.jpg";
import BtypePowder from "../../img/Btype/B1_powder.jpg";
import BtypeWIC from "../../img/Btype/B1_WIC.jpg";
import BtypeSEntrance from "../../img/Btype/B1_s_entrance.jpg";
import BtypeSLiving from "../../img/Btype/B1_s_living.jpg";
import BtypeSBed1 from "../../img/Btype/B1_s_bed1.jpg";
import BtypeSBed2 from "../../img/Btype/B1_s_bed2.jpg";
import BtypeSBed3 from "../../img/Btype/B1_s_bed3.jpg";
import BtypeSBath from "../../img/Btype/B1_s_bath.jpg";
import BtypeSLav from "../../img/Btype/B1_s_lavatory.jpg";
import BtypeSPowder from "../../img/Btype/B1_s_powder.jpg";
import BtypeSWIC from "../../img/Btype/B1_s_WIC.jpg";
import BtypeIEntrance from "../../img/Btype/B1_i_entrance.jpg";
import BtypeILiving from "../../img/Btype/B1_i_living.jpg";
import BtypeIBed1 from "../../img/Btype/B1_i_bed1.jpg";
import BtypeIBed2 from "../../img/Btype/B1_i_bed2.jpg";
import BtypeIBed3 from "../../img/Btype/B1_i_bed3.jpg";
import BtypeIBath from "../../img/Btype/B1_i_bath.jpg";
import BtypeILav from "../../img/Btype/B1_i_lavatory.jpg";
import BtypeIPowder from "../../img/Btype/B1_i_powder.jpg";
import BtypeIWIC from "../../img/Btype/B1_i_WIC.jpg";

const Btype = (props) => {
    const roomToggle = props.roomToggle;
    const versionToggle = props.versionToggle;
    return(
        <div className="room_type_toogle_img_frame">
            <div className={roomToggle === 1 ? "room_type_toogle_img_item" : "room_type_toogle_img_item none"}>
                <div className={versionToggle === 1 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={BtypeEntrance} />
                </div>
                <div className={versionToggle === 2 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={BtypeSEntrance} />
                </div>
                <div className={versionToggle === 3 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={BtypeIEntrance} />
                </div>
            </div>
            <div className={roomToggle === 2 ? "room_type_toogle_img_item" : "room_type_toogle_img_item none"}>
                <div className={versionToggle === 1 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={BtypeLiving} />
                </div>
                <div className={versionToggle === 2 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={BtypeSLiving} />
                </div>
                <div className={versionToggle === 3 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={BtypeILiving} />
                </div>
            </div>
            <div className={roomToggle === 3 ? "room_type_toogle_img_item" : "room_type_toogle_img_item none"}>
                <div className={versionToggle === 1 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={BtypeBed1} />
                </div>
                <div className={versionToggle === 2 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={BtypeSBed1} />
                </div>
                <div className={versionToggle === 3 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={BtypeIBed1} />
                </div>
            </div>
            <div className={roomToggle === 4 ? "room_type_toogle_img_item" : "room_type_toogle_img_item none"}>
                <div className={versionToggle === 1 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={BtypeBed2} />
                </div>
                <div className={versionToggle === 2 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={BtypeSBed2} />
                </div>
                <div className={versionToggle === 3 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={BtypeIBed2} />
                </div>
            </div>
            <div className={roomToggle === 5 ? "room_type_toogle_img_item" : "room_type_toogle_img_item none"}>
                <div className={versionToggle === 1 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={BtypeBed3} />
                </div>
                <div className={versionToggle === 2 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={BtypeSBed3} />
                </div>
                <div className={versionToggle === 3 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={BtypeIBed3} />
                </div>
            </div>
            <div className={roomToggle === 6 ? "room_type_toogle_img_item" : "room_type_toogle_img_item none"}>
                <div className={versionToggle === 1 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={BtypeBath} />
                </div>
                <div className={versionToggle === 2 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={BtypeSBath} />
                </div>
                <div className={versionToggle === 3 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={BtypeIBath} />
                </div>
            </div>
            <div className={roomToggle === 7 ? "room_type_toogle_img_item" : "room_type_toogle_img_item none"}>
                <div className={versionToggle === 1 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={BtypeLav} />
                </div>
                <div className={versionToggle === 2 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={BtypeSLav} />
                </div>
                <div className={versionToggle === 3 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={BtypeILav} />
                </div>
            </div>
            <div className={roomToggle === 8 ? "room_type_toogle_img_item" : "room_type_toogle_img_item none"}>
                <div className={versionToggle === 1 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={BtypePowder} />
                </div>
                <div className={versionToggle === 2 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={BtypeSPowder} />
                </div>
                <div className={versionToggle === 3 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={BtypeIPowder} />
                </div>
            </div>
            <div className={roomToggle === 9 ? "room_type_toogle_img_item" : "room_type_toogle_img_item none"}>
                <div className={versionToggle === 1 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={BtypeWIC} />
                </div>
                <div className={versionToggle === 2 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={BtypeSWIC} />
                </div>
                <div className={versionToggle === 3 ? "version_toggle_container" : "version_toggle_container none"}>
                    <img src={BtypeIWIC} />
                </div>
            </div>
        </div>
    )
}

export default Btype;