import React, { useState } from "react";
import PowderBase from "../../img/color_select1/powder-base.png"
import KitchenPanel1 from "../../img/color_select1/panel1.jpg"
import KitchenPanel2 from "../../img/color_select1/panel2.jpg"
import KitchenPanel3 from "../../img/color_select1/panel3.jpg"
import KitchenPanel4 from "../../img/color_select1/panel4.jpg"
import KitchenPanel5 from "../../img/color_select1/panel5.jpg"
import KitchenPanel6 from "../../img/color_select1/panel6.jpg"
import KitchenPanel7 from "../../img/color_select1/panel7.jpg"
import KitchenPanel8 from "../../img/color_select1/panel8.jpg"
import KitchenPanel9 from "../../img/color_select1/panel9.jpg"
import KitchenPanel10 from "../../img/color_select1/panel10.jpg"
import ColorSelectNav from "../../component/ColorSelectNav";
import Panel2 from "../../img/color_select1//pw-men/panel2.png"
import Panel1 from "../../img/color_select1//pw-men/panel1.png"
import Panel3 from "../../img/color_select1//pw-men/panel3.png"
import Panel4 from "../../img/color_select1//pw-men/panel4.png"
import Panel5 from "../../img/color_select1//pw-men/panel5.png"
import Panel6 from "../../img/color_select1//pw-men/panel6.png"
import Panel7 from "../../img/color_select1//pw-men/panel7.png"
import Panel8 from "../../img/color_select1//pw-men/panel8.png"
import Panel9 from "../../img/color_select1//pw-men/panel9.png"
import Panel10 from "../../img/color_select1//pw-men/panel10.png"

const PowderRoom = () => {
    const path = window.location.href
    const id = path.split('/#/')[1]
    const [panelToggle, setPanelToggle] = useState(1);
    const panelToggleTab = (index) => {
        setPanelToggle(index);
    };
    return(
        <div className="color_select_root">
            <div className="select_view_pow">
                <img src={PowderBase} />
                <div className="select_view_powder">
                    <img className={panelToggle === 1 ? "panel_show active" : "panel_show"} src={Panel1} />
                    <img className={panelToggle === 2 ? "panel_show active" : "panel_show"} src={Panel2} />
                    <img className={panelToggle === 3 ? "panel_show active" : "panel_show"} src={Panel3} />
                    <img className={panelToggle === 4 ? "panel_show active" : "panel_show"} src={Panel4} />
                    <img className={panelToggle === 5 ? "panel_show active" : "panel_show"} src={Panel5} />
                    <img className={panelToggle === 6 ? "panel_show active" : "panel_show"} src={Panel6} />
                    <img className={panelToggle === 7 ? "panel_show active" : "panel_show"} src={Panel7} />
                    <img className={panelToggle === 8 ? "panel_show active" : "panel_show"} src={Panel8} />
                    <img className={panelToggle === 9 ? "panel_show active" : "panel_show"} src={Panel9} />
                    <img className={panelToggle === 10 ? "panel_show active" : "panel_show"} src={Panel10} />
                </div>
            </div>
            <div className="select_menu">
                <div>
                    <div className="space_large_module"/>
                    <p>POWDERROOM PANEL</p>
                    <div className="slect_kitchen__plat">
                        <img onClick={() => panelToggleTab(1)} src={KitchenPanel1} />
                        <img onClick={() => panelToggleTab(2)} src={KitchenPanel2} />
                        <img onClick={() => panelToggleTab(3)} src={KitchenPanel3} />
                        <img onClick={() => panelToggleTab(4)} src={KitchenPanel4} />
                        <img onClick={() => panelToggleTab(5)} src={KitchenPanel5} />
                        <img onClick={() => panelToggleTab(6)} src={KitchenPanel6} />
                        <img onClick={() => panelToggleTab(7)} src={KitchenPanel7} />
                        <img onClick={() => panelToggleTab(8)} src={KitchenPanel8} />
                        <img onClick={() => panelToggleTab(9)} src={KitchenPanel9} />
                        <img onClick={() => panelToggleTab(10)} src={KitchenPanel10} />
                    </div>
                </div>
                <ColorSelectNav id={id} />
            </div>
        </div>
    )
}

export default PowderRoom;