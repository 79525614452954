import React, { useState } from "react";
import KitchenImage from "../../img/color_select1/kit-base.jpg"
import KitchenPanel1 from "../../img/color_select1/panel1.jpg"
import KitchenPanel2 from "../../img/color_select1/panel2.jpg"
import KitchenPanel3 from "../../img/color_select1/panel3.jpg"
import KitchenPanel4 from "../../img/color_select1/panel4.jpg"
import KitchenPanel5 from "../../img/color_select1/panel5.jpg"
import KitchenPanel6 from "../../img/color_select1/panel6.jpg"
import KitchenPanel7 from "../../img/color_select1/panel7.jpg"
import KitchenPanel8 from "../../img/color_select1/panel8.jpg"
import KitchenPanel9 from "../../img/color_select1/panel9.jpg"
import KitchenPanel10 from "../../img/color_select1/panel10.jpg"
import KitchenTop1 from "../../img/color_select1/JW01.jpg"
import KitchenTop2 from "../../img/color_select1/SD07.jpg"
import KitchenTop3 from "../../img/color_select1/MB01.jpg"
import KitchenFlooring1 from "../../img/color_select1/darkwood.jpg"
import KitchenFlooring2 from "../../img/color_select1/whiteoak.jpg"
import KitchenFlooring3 from "../../img/color_select1/lightbirch.jpg"
import KitchenFlooring4 from "../../img/color_select1/naturalmaple.jpg"
import KitchenFlooring5 from "../../img/color_select1/urbanchic.jpg"
import Panel2 from "../../img/color_select1//kit_men/panel2.png"
import Panel1 from "../../img/color_select1//kit_men/panel1.png"
import Panel3 from "../../img/color_select1//kit_men/panel3.png"
import Panel4 from "../../img/color_select1//kit_men/panel4.png"
import Panel5 from "../../img/color_select1//kit_men/panel5.png"
import Panel6 from "../../img/color_select1//kit_men/panel6.png"
import Panel7 from "../../img/color_select1//kit_men/panel7.png"
import Panel8 from "../../img/color_select1//kit_men/panel8.png"
import Panel9 from "../../img/color_select1//kit_men/panel9.png"
import Panel10 from "../../img/color_select1//kit_men/panel10.png"
import Top1 from "../../img/color_select1//kt_ten/juwelblack.png"
import Top2 from "../../img/color_select1//kt_ten/rosetea.png"
import Top3 from "../../img/color_select1//kt_ten/whiteplanet.png"
import Flooring1 from "../../img/color_select1//kit_fl/darkwood.png"
import Flooring2 from "../../img/color_select1//kit_fl/whiteoak.png"
import Flooring3 from "../../img/color_select1//kit_fl/lightbirch.png"
import Flooring4 from "../../img/color_select1//kit_fl/naturalmaple.png"
import Flooring5 from "../../img/color_select1//kit_fl/urbanchic.png"
import ColorSelectNav from "../../component/ColorSelectNav";

const Kitchen = () => {
    const path = window.location.href
    const id = path.split('/#/')[1]
    const [panelToggle, setPanelToggle] = useState(1);
    const [topToggle, setTopToggle] = useState(1);
    const [flooringToggle, setFooringToggle] = useState(1);
    const panelToggleTab = (index) => {
        setPanelToggle(index);
    };
    const topToggleTab = (index) => {
        setTopToggle(index);
    };
    const flooringToggleTab = (index) => {
        setFooringToggle(index);
    };
    return(
        <div className="color_select_root">
            <div className="select_view_kichen">
                <img src={KitchenImage} />
                <div className="select_view_panel_kitchen">
                    <img className={panelToggle === 1 ? "panel_show active" : "panel_show"} src={Panel1} />
                    <img className={panelToggle === 2 ? "panel_show active" : "panel_show"} src={Panel2} />
                    <img className={panelToggle === 3 ? "panel_show active" : "panel_show"} src={Panel3} />
                    <img className={panelToggle === 4 ? "panel_show active" : "panel_show"} src={Panel4} />
                    <img className={panelToggle === 5 ? "panel_show active" : "panel_show"} src={Panel5} />
                    <img className={panelToggle === 6 ? "panel_show active" : "panel_show"} src={Panel6} />
                    <img className={panelToggle === 7 ? "panel_show active" : "panel_show"} src={Panel7} />
                    <img className={panelToggle === 8 ? "panel_show active" : "panel_show"} src={Panel8} />
                    <img className={panelToggle === 9 ? "panel_show active" : "panel_show"} src={Panel9} />
                    <img className={topToggle === 10 ? "panel_show active" : "panel_show"} src={Panel10} />
                    <img className={panelToggle === 10 ? "panel_show active" : "panel_show"} src={Panel10} />
                </div>
                <div className="select_view_panel_kitchen">
                    <img className={topToggle === 1 ? "panel_show active" : "panel_show"} src={Top1} />
                    <img className={topToggle === 2 ? "panel_show active" : "panel_show"} src={Top2} />
                    <img className={topToggle === 3 ? "panel_show active" : "panel_show"} src={Top3} />
                </div>
                <div className="select_view_panel_kitchen">
                    <img className={flooringToggle === 1 ? "panel_show active" : "panel_show"} src={Flooring1} />
                    <img className={flooringToggle === 2 ? "panel_show active" : "panel_show"} src={Flooring2} />
                    <img className={flooringToggle === 3 ? "panel_show active" : "panel_show"} src={Flooring3} />
                    <img className={flooringToggle === 4 ? "panel_show active" : "panel_show"} src={Flooring4} />
                    <img className={flooringToggle === 5 ? "panel_show active" : "panel_show"} src={Flooring5} />
                </div>
            </div>
            <div className="select_menu">
                <div>
                    <div className="space_sp_small_module" />
                    <div className="space_sp_small_module" />
                    <div className="space_sp_small_module" />
                    <p>KITCHEN PANEL</p>
                    <div className="slect_kitchen__plat">
                        <img onClick={() => panelToggleTab(1)} src={KitchenPanel1} />
                        <img onClick={() => panelToggleTab(2)} src={KitchenPanel2} />
                        <img onClick={() => panelToggleTab(3)} src={KitchenPanel3} />
                        <img onClick={() => panelToggleTab(4)} src={KitchenPanel4} />
                        <img onClick={() => panelToggleTab(5)} src={KitchenPanel5} />
                        <img onClick={() => panelToggleTab(6)} src={KitchenPanel6} />
                        <img onClick={() => panelToggleTab(7)} src={KitchenPanel7} />
                        <img onClick={() => panelToggleTab(8)} src={KitchenPanel8} />
                        <img onClick={() => panelToggleTab(9)} src={KitchenPanel9} />
                        <img onClick={() => panelToggleTab(10)} src={KitchenPanel10} />
                    </div>
                    <div className="space_midle_module" />
                    <p>KITCHEN TOP</p>
                    <div className="slect_kitchen__plat">
                        <img onClick={() => topToggleTab(1)} src={KitchenTop1} />
                        <img onClick={() => topToggleTab(2)} src={KitchenTop2} />
                        <img onClick={() => topToggleTab(3)} src={KitchenTop3} />
                    </div>
                    {/* <div className="space_midle_module" /> */}
                    <p>FLOORING</p>
                    <div className="slect_kitchen__plat">
                        <img onClick={() => flooringToggleTab(1)} src={KitchenFlooring1} />
                        <img onClick={() => flooringToggleTab(2)} src={KitchenFlooring2} />
                        <img onClick={() => flooringToggleTab(3)} src={KitchenFlooring3} />
                        <img onClick={() => flooringToggleTab(4)} src={KitchenFlooring4} />
                        <img onClick={() => flooringToggleTab(5)} src={KitchenFlooring5} />
                    </div>
                </div>
                <ColorSelectNav id={id} />
            </div>
        </div>
    )
}

export default Kitchen;