import React from "react"; 

const PriceTableItem = (props) => {
    const doc = props.doc
    const roomNum = props.roomNum
    return(
        <tr>
            <th>{props.index + 1}F</th>
            {doc.map((room) => (
                <td style={room.num === roomNum ? {backgroundColor: "red"} : {backgroundColor: "grey"}}></td>
            ))}
        </tr>
    )
}

export default PriceTableItem;