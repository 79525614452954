import React from "react";
import SequlityMenu from "../component/SequlityMenu";
import SequlityImage from "../img/sequlity/seculity2.png"

const AfterSales = () => {
    const path = window.location.href
    const id = path.split('/#/')[1]
    return(
        <div className="sequlity_root">
            <div className="sequlity_title">
                <h2>QUALITY</h2>
                <SequlityMenu id={id} />
            </div>
            <div className="sequlity_view">
                <img src={SequlityImage} />
            </div>
        </div>
    )
}

export default AfterSales;