import React from "react";
import IconMatome from "../img/hazardmap _parts/icon_matome.jpg"
import Hm1 from "../img/hazardmap _parts/hm1.jpg"
import Hm2 from "../img/hazardmap _parts/hm2.jpg"
import Hm3 from "../img/hazardmap _parts/hm3.jpg"
import Hm4 from "../img/hazardmap _parts/hm4.jpg"
import Hm6 from "../img/hazardmap _parts/hm6.jpg"
import Hm7 from "../img/hazardmap _parts/hm7.jpg"
import Gaikan from "../img/map icon/gaikan-icon.jpg"
import HazardMapJpg from "../img/hazardmap _parts/hazardmap.jpg"
import { useNavigate } from "react-router-dom";
import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import MapMenuButtonList from "../component/MapMenuButtonList";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
};
const style_v = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
};

const HazardMap = () => {
    const path = window.location.href
    const id = path.split('/#/')[1]
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [open4, setOpen4] = useState(false);
    const [open5, setOpen5] = useState(false);
    const [open6, setOpen6] = useState(false);
    const navigate = useNavigate();
    const handleOpen1 = () => setOpen1(true);
    const handleClose1 = () => setOpen1(false);
    const handleOpen2 = () => setOpen2(true);
    const handleClose2 = () => setOpen2(false);
    const handleOpen3 = () => setOpen3(true);
    const handleClose3 = () => setOpen3(false);
    const handleOpen4 = () => setOpen4(true);
    const handleClose4 = () => setOpen4(false);
    const handleOpen5 = () => setOpen5(true);
    const handleClose5 = () => setOpen5(false);
    const handleOpen6 = () => setOpen6(true);
    const handleClose6 = () => setOpen6(false);
    return(
        <div className="map_root">
            <div className="map">
                <div className="map_view">
                    <h2>HAZARD MAP</h2>
                    <div className="space_small_module" />
                    <div className="hazard_map">
                        <div className="hazard_map_view">
                            <div className="hazard_map_relative">
                                <img src={HazardMapJpg} />
                                <div className="hazard_map_absolute">
                                    <img src={Gaikan} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hazard_map_images">
                    <div className="hazard_map_relative">
                        <img src={IconMatome} />
                        <div onClick={handleOpen1} className="hazard_image1"></div>
                        <Modal
                            open={open1}
                            onClose={handleClose1}
                        >
                            <Box sx={style}>
                                <img style={{width: "100%"}} onClick={handleClose1} src={Hm1} />
                            </Box>
                        </Modal>
                        <div onClick={handleOpen2} className="hazard_image2"></div>
                        <Modal
                            open={open2}
                            onClose={handleClose2}
                        >
                            <Box sx={style_v}>
                                <img style={{height: "80vh"}} onClick={handleClose2} src={Hm6} />
                            </Box>
                        </Modal>
                        <div onClick={handleOpen3} className="hazard_image3"></div>
                        <Modal
                            open={open3}
                            onClose={handleClose3}
                        >
                            <Box sx={style}>
                                <img style={{width: "100%"}} onClick={handleClose3} src={Hm4} />
                            </Box>
                        </Modal>
                        <div onClick={handleOpen4} className="hazard_image4"></div>
                        <Modal
                            open={open4}
                            onClose={handleClose4}
                        >
                            <Box sx={style}>
                                <img style={{width: "100%"}} onClick={handleClose4} src={Hm3} />
                            </Box>
                        </Modal>
                        <div onClick={handleOpen5} className="hazard_image5"></div>
                        <Modal
                            open={open5}
                            onClose={handleClose5}
                        >
                            <Box sx={style}>
                                <img style={{width: "100%"}} onClick={handleClose5} src={Hm2} />
                            </Box>
                        </Modal>
                        <div onClick={handleOpen6} className="hazard_image6"></div>
                        <Modal
                            open={open6}
                            onClose={handleClose6}
                        >
                            <Box sx={style}>
                                <img style={{width: "100%"}} onClick={handleClose6} src={Hm7} />
                            </Box>
                        </Modal>
                    </div>
                    <div className="space_small_module" />
                    <MapMenuButtonList id={id} />
                </div>
            </div>
        </div>
    )
}

export default HazardMap;