import React, { useEffect, useState } from "react";
import MenuList from "../component/MenuList";
import PriceTableItem from "../component/PriceTableItem";
import RoomNum from "../component/Room_Num";
import Azumen from '../img/room_sozai/A_zu.png'
import Amm from '../img/roomplan_sozai2/Amm.png'
import Bzumen from '../img/room_sozai/B_zu.png'
import Bmm from '../img/roomplan_sozai2/bmm.png'
import Czumen from '../img/room_sozai/C_zu.png'
import Cmm from '../img/roomplan_sozai2/cmm.png'
import Dzumen from '../img/room_sozai/D_zu.png'
import Dmm from '../img/roomplan_sozai2/dmm.png'
import Ezumen from '../img/room_sozai/E_zu.png'
import Emm from '../img/roomplan_sozai2/emm.png'
import { doc, getDoc, query } from "firebase/firestore";
import { db } from "../firebase";

const PriceList = () => {
    const path = window.location.href
    const id = path.split('/#/')[1]
    const [room, setRoom] = useState([]);
    useEffect(() => {
        const roomRef = doc(db, 'apartment', 'sIXBcOFB9TRMxTh8vI5b');
        const q = query(roomRef);
        getDoc(q).then(snapshot => {
            const data = snapshot.data();
            setRoom(data.room)
        })
    }, [])
    const dataByHierarchy = {};
    for (const data of room) {
      const hierarchy = data.hierarchy;
      if (!dataByHierarchy[hierarchy]) {
        dataByHierarchy[hierarchy] = [];
      }
      dataByHierarchy[hierarchy].push(data);
    }
    const dividedList = Object.values(dataByHierarchy);
    const [imgToggle, setImgToggle] = useState("A Type");
    const [roomNum, setRoomNum] = useState("101");
    return(
    <div className="root">
        <div className="main_container">
            <div className='price_list'>
                <h2>PRICE LIST</h2>
                <div className="price_list_content">
                    <div className="price_list_view">
                        <table>
                            {dividedList.map((value, index) => <PriceTableItem value={value.id} doc={value} index={index} roomNum={roomNum} />)}
                        </table>
                    </div>
                    <div className={imgToggle === "A Type" ? "price_list_img active" : "price_list_img"}>
                        <div className="room_image">
                            <img src={Azumen} />
                        </div>
                        <img style={{width: "80%"}} src={Amm} />
                    </div>
                    <div className={imgToggle === "B Type" ? "price_list_img active" : "price_list_img"}>
                        <div className="room_image">
                            <img src={Bzumen} />
                        </div>
                        <img style={{width: "80%"}} src={Bmm} />
                    </div>
                    <div className={imgToggle === "C Type" ? "price_list_img active" : "price_list_img"}>
                        <div className="room_image">
                            <img src={Czumen} />
                        </div>
                        <img style={{width: "80%"}} src={Cmm} />
                    </div>
                    <div className={imgToggle === "D Type" ? "price_list_img_e active" : "price_list_img_e"}>
                        <div className="room_image">
                            <img src={Dzumen} />
                        </div>
                        <img style={{width: "80%"}} src={Dmm} />
                    </div>
                    <div className={imgToggle === "E Type" ? "price_list_img_e active" : "price_list_img_e"}>
                        <div className="room_image">
                            <img src={Ezumen} />
                        </div>
                        <img style={{width: "80%"}} src={Emm} />
                    </div>
                </div>
            </div>
            <div className="room_num">
                <div className="room_menu">
                    <MenuList id={id} />
                </div>
                <div className="room_list">
                    <div className="room_type_list">
                        {dividedList.map((value) => <RoomNum setImgToggle={setImgToggle} roomNum={roomNum} setRoomNum={setRoomNum} doc={value}  />)}
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default PriceList