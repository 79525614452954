import React, {useEffect, useState} from "react";
import Parking from "../img/car/parking_base00.jpg"
import ParkingK from "../img/car/parking_baseKjpg.jpg"
import Caution from "../img/caution.png"
import Car1 from "../img/car/1.png"
import Car2 from "../img/car/2.png"
import Car3 from "../img/car/3.png"
import Car4 from "../img/car/4.png"
import Car7 from "../img/car/7.png"
import Car8 from "../img/car/8.png"
import Car9 from "../img/car/9.png"
import Car10 from "../img/car/10.png"
import Car11 from "../img/car/11.png"
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { doc, getDoc, query, updateDoc } from "firebase/firestore";
import { db } from "../firebase";

const Monthly = () => {
    const [open, setOpen] = useState(false);
    const [parking, setParking] = useState(new Array(22).fill(false))
    const [showCar, setShowCar] = useState([])
    const [toggle, setToggle] = useState(0)
    const showCarTab = (index) => {
        setParking(prevPark => {
            const reserved = [...prevPark];
            reserved[index] = !reserved[index];
            return reserved
        })
    }
    const toggleTab = (index) => {
        setToggle(index)
    }
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    useEffect(() => {
        const parkRef = doc(db, 'apartment', 'sIXBcOFB9TRMxTh8vI5b');
        const q = query(parkRef);
        getDoc(q).then(snapshot => {
            const data = snapshot.data();
            setShowCar(data.parking);
            setParking(data.parking);
        })
    }, [open])
    const editParking = async() => {
        const parkRef = doc(db, 'apartment', 'sIXBcOFB9TRMxTh8vI5b')
        const addPark = await updateDoc(parkRef, {
            parking: parking,
        }).then(() => {
            alert('駐車場の空車情報を更新しました')
            handleClose()
        }).catch((e) => {
            alert(`エラー：${e}`)
        })
    }
    return(
        <div className="monthly_root">
            <div className="monthly_title">
                <h2>MONTHLY COST/PARKING</h2>
                <div className="">
                    <button onClick={handleClickOpen}>Controll Panel</button>
                    <Dialog         
                        maxWidth="lg"
                        fullWidth
                        open={open}
                        onClose={handleClose}
                    >
                        <DialogContent>
                        <div className="parking">
                            <img className="park_image" src={ParkingK} />
                            <div onClick={() => showCarTab(1)} className={parking[1] === true ? "parking1" : "non_parking1"}>
                                <img src={Car1} />
                            </div>
                            <div onClick={() => showCarTab(2)} className={parking[2] === true ? "parking2" : "non_parking2"}>
                                <img src={Car2} />
                            </div>
                            <div onClick={() => showCarTab(3)} className={parking[3] === true ? "parking3" : "non_parking3"}>
                                <img src={Car3} />
                            </div>
                            <div onClick={() => showCarTab(4)} className={parking[4] === true ? "parking4" : "non_parking4"}>
                                <img src={Car4} />
                            </div>
                            <div onClick={() => showCarTab(5)} className={parking[5] === true ? "parking5" : "non_parking5"}>
                                <img src={Car8} />
                            </div>
                            <div onClick={() => showCarTab(6)} className={parking[6] === true ? "parking6" : "non_parking6"}>
                                <img src={Car7} />
                            </div>
                            <div onClick={() => showCarTab(7)} className={parking[7] === true ? "parking7" : "non_parking7"}>
                                <img src={Car8} />
                            </div>
                            <div onClick={() => showCarTab(8)} className={parking[8] === true ? "parking8" : "non_parking8"}>
                                <img src={Car9} />
                            </div>
                            <div onClick={() => showCarTab(9)} className={parking[9] === true ? "parking9" : "non_parking9"}>
                                <img src={Car10} />
                            </div>
                            <div onClick={() => showCarTab(10)} className={parking[10] === true ? "parking10" : "non_parking10"}>
                                <img src={Car11} />
                            </div>
                            <div onClick={() => showCarTab(11)} className={parking[11] === true ? "parking11" : "non_parking11"}>
                                <img src={Car1} />
                            </div>
                            <div onClick={() => showCarTab(12)} className={parking[12] === true ? "parking12" : "non_parking12"}>
                                <img src={Car2} />
                            </div>
                            <div onClick={() => showCarTab(13)} className={parking[13] === true ? "parking13" : "non_parking13"}>
                                <img src={Car3} />
                            </div>
                            <div onClick={() => showCarTab(14)} className={parking[14] === true ? "parking14" : "non_parking14"}>
                                <img src={Car4} />
                            </div>
                            <div onClick={() => showCarTab(15)} className={parking[15] === true ? "parking15" : "non_parking15"}>
                                <img src={Car1} />
                            </div>
                            <div onClick={() => showCarTab(16)} className={parking[16] === true ? "parking16" : "non_parking16"}>
                                <img src={Car7} />
                            </div>
                            <div onClick={() => showCarTab(17)} className={parking[17] === true ? "parking17" : "non_parking17"}>
                                <img src={Car8} />
                            </div>
                            <div onClick={() => showCarTab(18)} className={parking[18] === true ? "parking18" : "non_parking18"}>
                                <img src={Car9} />
                            </div>
                            <div onClick={() => showCarTab(19)} className={parking[19] === true ? "parking19" : "non_parking19"}>
                                <img src={Car10} />
                            </div>
                            <div onClick={() => showCarTab(20)} className={parking[20] === true ? "parking20" : "non_parking20"}>
                                <img src={Car10} />
                            </div>
                            <div onClick={() => showCarTab(21)} className={parking[21] === true ? "parking21" : "non_parking21"}>
                                <img src={Car10} />
                            </div>
                            <div onClick={() => showCarTab(22)} className={parking[22] === true ? "parking22" : "non_parking22"}>
                                <img src={Car10} />
                            </div>
                            <Button style={{float: "left"}} onClick={() => editParking()}>空車情報を決定</Button>
                        </div>
                        </DialogContent>
                    </Dialog>
                </div>
            </div>
            <div className="monthly_flex">
                <div className="monthly_view">
                    <div className="parking">
                        <img src={Parking} />
                        <div onClick={() => toggleTab(1)} className={showCar[1] === true ? "park1" : "non_park1"}>
                            <img src={Car1} />
                        </div>
                        <div onClick={() => toggleTab(2)} className={showCar[2] === true ? "park2" : "non_park2"}>
                            <img src={Car2} />
                        </div>
                        <div onClick={() => toggleTab(3)} className={showCar[3] === true ? "park3" : "non_park3"}>
                            <img src={Car3} />
                        </div>
                        <div onClick={() => toggleTab(4)} className={showCar[4] === true ? "park4" : "non_park4"}>
                            <img src={Car4} />
                        </div>
                        <div onClick={() => toggleTab(5)} className={showCar[5] === true ? "park5" : "non_park5"}>
                            <img src={Car8} />
                        </div>
                        <div onClick={() => toggleTab(6)} className={showCar[6] === true ? "park6" : "non_park6"}>
                            <img src={Car7} />
                        </div>
                        <div onClick={() => toggleTab(7)} className={showCar[7] === true ? "park7" : "non_park7"}>
                            <img src={Car8} />
                        </div>
                        <div onClick={() => toggleTab(8)} className={showCar[8] === true ? "park8" : "non_park8"}>
                            <img src={Car9} />
                        </div>
                        <div onClick={() => toggleTab(9)} className={showCar[9] === true ? "park9" : "non_park9"}>
                            <img src={Car10} />
                        </div>
                        <div onClick={() => toggleTab(10)} className={showCar[10] === true ? "park10" : "non_park10"}>
                            <img src={Car11} />
                        </div>
                        <div onClick={() => toggleTab(11)} className={showCar[11] === true ? "park11" : "non_park11"}>
                            <img src={Car1} />
                        </div>
                        <div onClick={() => toggleTab(12)} className={showCar[12] === true ? "park12" : "non_park12"}>
                            <img src={Car2} />
                        </div>
                        <div onClick={() => toggleTab(13)} className={showCar[13] === true ? "park13" : "non_park13"}>
                            <img src={Car3} />
                        </div>
                        <div onClick={() => toggleTab(14)} className={showCar[14] === true ? "park14" : "non_park14"}>
                            <img src={Car4} />
                        </div>
                        <div onClick={() => toggleTab(15)} className={showCar[15] === true ? "park15" : "non_park15"}>
                            <img src={Car1} />
                        </div>
                        <div onClick={() => toggleTab(16)} className={showCar[16] === true ? "park16" : "non_park16"}>
                            <img src={Car7} />
                        </div>
                        <div onClick={() => toggleTab(17)} className={showCar[17] === true ? "park17" : "non_park17"}>
                            <img src={Car8} />
                        </div>
                        <div onClick={() => toggleTab(18)} className={showCar[18] === true ? "park18" : "non_park18"}>
                            <img src={Car9} />
                        </div>
                        <div onClick={() => toggleTab(19)} className={showCar[19] === true ? "park19" : "non_park19"}>
                            <img src={Car10} />
                        </div>
                        <div onClick={() => toggleTab(20)} className={showCar[20] === true ? "park20" : "non_park20"}>
                            <img src={Car10} />
                        </div>
                        <div onClick={() => toggleTab(21)} className={showCar[21] === true ? "park21" : "non_park21"}>
                            <img src={Car10} />
                        </div>
                        <div onClick={() => toggleTab(22)} className={showCar[22] === true ? "park22" : "non_park22"}>
                            <img src={Car10} />
                        </div>
                    </div>
                </div>
                <div className="monthly_list">
                    <div className="monthly_table">
                        <table>
                            <tr>
                                <th>種類</th>
                                <th>車種</th>
                                <th>区分番号</th>
                                <th>月額使用料</th>
                            </tr>
                            <tr>
                                <td className={toggle === 1 || toggle === 5 || toggle === 7 || toggle === 10 || toggle === 18 || toggle === 4 || toggle === 9 || toggle === 13 ? "acv_td" : "td"}>屋内平面駐車場</td>
                                <td className={toggle === 1 || toggle === 5 || toggle === 7 || toggle === 10 || toggle === 18 || toggle === 4 || toggle === 9 || toggle === 13 ? "acv_td" : "td"}>普通</td>
                                <td className={toggle === 1 || toggle === 5 || toggle === 7 || toggle === 10 || toggle === 18 || toggle === 4 || toggle === 9 || toggle === 13 ? "acv_td" : "td"}>No.7,8,11,12</td>
                                <td className={toggle === 1 || toggle === 5 || toggle === 7 || toggle === 10 || toggle === 18 || toggle === 4 || toggle === 9 || toggle === 13 ? "acv_td" : "td"}>8000円</td>
                            </tr>
                            <tr>
                                <td className={toggle === 6 || toggle === 21 || toggle === 17 || toggle === 8 || toggle === 19 || toggle === 14 || toggle === 12 || toggle === 16 || toggle === 22 || toggle === 11 || toggle === 2 || toggle === 3 || toggle === 15 ? "acv_td" : "td"} rowSpan={3}>屋外平面駐車場</td>
                                <td className={toggle === 6 || toggle === 21 || toggle === 17 || toggle === 8 || toggle === 19 || toggle === 14 || toggle === 12 || toggle === 16 || toggle === 22 || toggle === 11 || toggle === 2 || toggle === 3 || toggle === 15 ? "acv_td" : "td"} rowSpan={3}>普通</td>
                                <td className={toggle === 3 || toggle === 8 || toggle === 19 || toggle === 14 || toggle === 12 || toggle === 16 || toggle === 3 || toggle === 15 ? "acv_td" : "td"}>No.7,8,11,12</td>
                                <td className={toggle === 3 || toggle === 8 || toggle === 19 || toggle === 14 || toggle === 12 || toggle === 16 || toggle === 3 || toggle === 15 ? "acv_td" : "td"}>7000円</td>
                            </tr>
                            <tr>
                                <td className={toggle === 6 || toggle === 21 ? "acv_td" : "td"}>No.15,18</td>
                                <td className={toggle === 6 || toggle === 21 ? "acv_td" : "td"}>6500円</td>
                            </tr>
                            <tr>
                                <td className={toggle === 22 || toggle === 11 || toggle === 2 ? "acv_td" : "td"}>No.31~41</td>
                                <td className={toggle === 22 || toggle === 11 || toggle === 2 ? "acv_td" : "td"}>6000円</td>
                            </tr>
                            <tr>
                                <td className={toggle === 20 ? "acv_td" : "td"}>屋外平面縦型駐車場</td>
                                <td className={toggle === 20 ? "acv_td" : "td"}>普通+普通</td>
                                <td className={toggle === 20 ? "acv_td" : "td"}>No.29,30</td>
                                <td className={toggle === 20 ? "acv_td" : "td"}>12000円</td>
                            </tr>
                        </table>
                    </div>
                    <div className="monthly_table2">
                        <table>
                            <tr>
                                <th>種類</th>
                                <th>管理費<br/>（月額）</th>
                                <th>修繕積立費<br/>（月額）</th>
                                <th>合計<br/>（月額）</th>
                                <th>修繕積立基金<br/>一時払い</th>
                            </tr>
                            <tr>
                                <td>Atype</td>
                                <td>9,750円</td>
                                <td>9,850円</td>
                                <td>19,600円</td>
                                <td>392,800円</td>
                            </tr>
                            <tr>
                                <td>Btype</td>
                                <td>10,250円</td>
                                <td>9,960円</td>
                                <td>20,200円</td>
                                <td>425,600円</td>
                            </tr>
                            <tr>
                                <td>Ctype</td>
                                <td>11,000円</td>
                                <td>10,500円</td>
                                <td>21,500円</td>
                                <td>425,600円</td>
                            </tr>
                            <tr>
                                <td>Dtype</td>
                                <td>12,000円</td>
                                <td>11,000円</td>
                                <td>23,000円</td>
                                <td>520,700円</td>
                            </tr>
                            <tr>
                                <td>Etype</td>
                                <td>13,500円</td>
                                <td>12,000円</td>
                                <td>25,500円</td>
                                <td>604,600円</td>
                            </tr>
                        </table>
                    </div>
                    <img src={Caution} />
                </div>
            </div>
        </div>
    )
}

export default Monthly