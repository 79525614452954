import React from "react";
import { useNavigate } from "react-router-dom";

const MapMenuButtonList = (props) => {
    const navigate = useNavigate();
    const id = props.id;
    return(
        <div className="map_menu_button_list">
            <button onClick={() => navigate('/map')} className={id === "map" ? "map_menu_button active" : "map_menu_button"}>
                GOOGLE MAP
            </button>
            <button onClick={() => navigate('/aerial_shoot')} className={id === "aerial_shoot" ? "map_menu_button active" : "map_menu_button"}>
                AERIAL SHOOT
            </button>
            <button onClick={() => navigate('/life_infomation')} className={id === "life_infomation" ? "map_menu_button active" : "map_menu_button"}>
                LIFE INFORMATION
            </button>
            <button onClick={() => navigate('/hazard_map')} className={id === "hazard_map" ? "map_menu_button active" : "map_menu_button"}>
                HAZARD MAP
            </button>
        </div>
    )
}

export default MapMenuButtonList;