import React from "react";
import { useNavigate } from "react-router-dom";
import Alogo from "../img/room_sozai/a_logo.png"
import Blogo from "../img/room_sozai/b_logo.png"
import Clogo from "../img/room_sozai/c_logo.png"
import Dlogo from "../img/room_sozai/d_logo.png"
import Elogo from "../img/room_sozai/e_logo.png"

const TypeButton = (props) => {
    const navigate = useNavigate();
    const id = props.id;
    return(
        <div className="room_type_button_list">
            <div className={id === "roomplan_a" ? "room_type_button active" : "room_type_button"}>
            <button onClick={() => navigate('/roomplan_a')}><img src={Alogo} /></button>
            </div>
            <div className={id === "roomplan_b" ? "room_type_button active" : "room_type_button"}>
            <button onClick={() => navigate('/roomplan_b')}><img src={Blogo} /></button>
            </div>
            <div className={id === "roomplan_c" ? "room_type_button active" : "room_type_button"}>
            <button onClick={() => navigate('/roomplan_c')}><img src={Clogo} /></button>
            </div>
            <div className={id === "roomplan_d" ? "room_type_button active" : "room_type_button"}>
            <button onClick={() => navigate('/roomplan_d')}><img src={Dlogo} /></button>
            </div>
            <div className={id === "roomplan_e" ? "room_type_button active" : "room_type_button"}>
            <button onClick={() => navigate('/roomplan_e')}><img src={Elogo} /></button>
            </div>
        </div>
    )
}

export default TypeButton;