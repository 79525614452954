import React, { useState } from "react";
import Title from '../img/roomplan_sozai2/roomplan_title.png'
import FloorPlanImg from '../img/floorplan/floorplan_zu.png'
import TypeButton from "../component/TypeButton";
import MenuList from "../component/MenuList";
import FloorPlanMenu from "../component/FloorPlanMenu";

const RoomPlan = () => {
    const path = window.location.href
    const id = path.split('/#/')[1]
    return(
    <div className="root">
      <div className="main_container">
        <div className='room_plan_x'>
          <h2><img src={Title} /></h2>
        </div>
        <div className='room_view'>
          <div className="room_menu">
              <MenuList id={id} />
              <div className="space_small_module" />
              <TypeButton id={id} />
              <div className="space_small_module" />
              <FloorPlanMenu id={id} />
          </div>
          <div className="type_room_x">
              <div className="active_content">
                  <img src={FloorPlanImg} />
              </div>
          </div>
        </div>
      </div>
    </div>
    )
}

export default RoomPlan;