import React from "react";
import { useNavigate } from "react-router-dom";

const SequlityMenu = (props) => {
    const navigate = useNavigate()
    const id = props.id;
    return(
        <div className="sequlity_button_list">
            <button onClick={() => navigate('/sequlity')} className={id === "sequlity" ? "sequlity_button active" : "sequlity_button"}>QUALITY CHECK</button>
            <button onClick={() => navigate('/after_sales')} className={id === "after_sales" ? "sequlity_button active" : "sequlity_button"}>AFTER SALES<br /> SERVICE</button>
        </div>
    )
}
export default SequlityMenu;