import React, { useState } from "react";
import Title from '../img/roomplan_sozai2/roomplan_title.png'
import Bzumen from '../img/room_sozai/B_zu.png'
import Bmm from '../img/roomplan_sozai2/bmm.png'
import Kids from '../img/Bsozai/b_kids.jpg'
import Shoose from '../img/Bsozai/b_shoose.jpg'
import Bed from '../img/Bsozai/b_bed.jpg'
import Wc from '../img/Bsozai/b_wc.jpg'
import Japanese from '../img/Bsozai/b_japan.jpg'
import Wash from '../img/Bsozai/b_wash.jpg'
import Living from '../img/Bsozai/b_living.jpg'
import Bath from '../img/Bsozai/b_bath.jpg'
import TypeButton from "../component/TypeButton";
import MenuList from "../component/MenuList";
import FloorPlanMenu from "../component/FloorPlanMenu";

const RoomPlanB = () => {
  const path = window.location.href
  const id = path.split('/#/')[1]
  const [imgToggle, setImgToggle] = useState(1);
  const [planToggle, setPlanToggle] = useState(0);
  const imgToggleTab = (index) => {
    setImgToggle(index);
  };
    return(
    <div className="root">
      <div className="main_container">
        <div className='room_plan'>
          <h2>ROOM PLAN</h2>
          <div>
            <div className='room_type_view'>
              <img src={Bzumen} />
              <div onClick={() => imgToggleTab(8)} className={imgToggle === 8 ? "active_point1_b" : "point1_b"} />
              <div onClick={() => imgToggleTab(2)} className={imgToggle === 2 ? "active_point2_b" : "point2_b"} />
              <div onClick={() => imgToggleTab(3)} className={imgToggle === 3 ? "active_point3_b" : "point3_b"} />
              <div onClick={() => imgToggleTab(4)} className={imgToggle === 4 ? "active_point4_b" : "point4_b"} />
              <div onClick={() => imgToggleTab(5)} className={imgToggle === 5 ? "active_point5_b" : "point5_b"} />
              <div onClick={() => imgToggleTab(6)} className={imgToggle === 6 ? "active_point6_b" : "point6_b"} />
              <div onClick={() => imgToggleTab(7)} className={imgToggle === 7 ? "active_point7_b" : "point7_b"} />
              <div onClick={() => imgToggleTab(1)} className={imgToggle === 1 ? "active_point8_b" : "point8_b"} />
            </div>
            <div className="space_small_module" />
            <img className="room_type_tab" src={Bmm} />
          </div>
        </div>
        <div className='room_view'>
          <div className="room_menu">
            <MenuList id={id} />
            <div className="space_small_module" />
            <TypeButton id={id} />
            <div className="space_small_module" />
            <FloorPlanMenu planToggle={planToggle} setPlanToggle={setPlanToggle} />
          </div>
          <div className="space_small_module" />
          <div className="type_room">
            <div className={imgToggle === 8 ? "active_content" : "contet"}>
              <img src={Kids} />
              <p>子供部屋</p>
            </div>
            <div className={imgToggle === 2 ? "active_content" : "contet"}>
              <img src={Shoose} />
              <p>靴箱</p>
            </div>
            <div className={imgToggle === 3 ? "active_content" : "contet"}>
              <img src={Bed} />
              <p>寝室</p>
            </div>
            <div className={imgToggle === 4 ? "active_content" : "contet"}>
              <img src={Wc} />
              <p>トイレ</p>
            </div>
            <div className={imgToggle === 5 ? "active_content" : "contet"}>
              <img src={Japanese} />
              <p>和室</p>
            </div>
            <div className={imgToggle === 6 ? "active_content" : "contet"}>
              <img src={Wash} />
              <p>洗面所</p>
            </div>
            <div className={imgToggle === 7 ? "active_content" : "contet"}>
              <img src={Bath} />
              <p>浴室</p>
            </div>
            <div className={imgToggle === 1 ? "active_content" : "contet"}>
              <img src={Living} />
              <p>リビング・ダイニングキッチン 16.7畳</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
}

export default RoomPlanB;