import React, { useState } from "react";
import ToiletImage from "../../img/color_select1/toiletwhite.png"
import ToiletImage2 from "../../img/color_select1/toiletivory.png"
import ToiletImage3 from "../../img/color_select1/toiletpink.png"
import Toilet1 from "../../img/color_select1/toilet1ico.jpg"
import Toilet2 from "../../img/color_select1/toilet2ico.jpg"
import Toilet3 from "../../img/color_select1/toilet3ico.jpg"
import ColorSelectNav from "../../component/ColorSelectNav";

const Lavatory = () => {
    const path = window.location.href
    const id = path.split('/#/')[1]
    const [panelToggle, setPanelToggle] = useState(1);
    const panelToggleTab = (index) => {
        setPanelToggle(index);
    };
    return(
        <div className="color_select_root">
            <div className="select_view_wc">
                <img className={panelToggle === 1 ? "panel_show active" : "panel_show"} src={ToiletImage} />
                <img className={panelToggle === 2 ? "panel_show active" : "panel_show"} src={ToiletImage2} />
                <img className={panelToggle === 3 ? "panel_show active" : "panel_show"} src={ToiletImage3} />
            </div>
            <div className="select_menu">
                <div>
                    <div className="space_large_module" />
                    <p>TOILET COLOR</p>
                    <div className="slect_plat">
                        <img onClick={() => panelToggleTab(1)} src={Toilet1} />
                        <img onClick={() => panelToggleTab(2)} src={Toilet2} />
                        <img onClick={() => panelToggleTab(3)} src={Toilet3} />
                    </div>
                </div>
                <ColorSelectNav id={id} />
            </div>
        </div>
    )
}

export default Lavatory;